import { Alignment } from "$utils/enum.utils";
import { IconButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Modal } from "@mui/material";
import styled from "styled-components";
import { ShapeIcon } from "../ShapeIcon";

export const ModalContainer = styled.div`
  background-color: ${(props) => props.theme.colors.neutral[100]};
  border-radius: ${(props) => props.theme.borders.radius.l};
  max-height: calc(100vh - 15px);
`;

export const StyledActionModal = styled(Modal)`
  &.MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    outline: none;
  }
`;

export const ModalBodyContainer = styled.div`
  padding: ${(props) =>
    `0 ${props.theme.spacings.m} ${props.theme.spacings.xs} ${props.theme.spacings.m}`};
  overflow: auto;
  max-height: calc(100vh - 200px);
`;

export const ModalHeaderContainer = styled.div<{ iconPosition: Alignment }>`
  position: relative;
  padding: ${(props) => props.theme.spacings.xs}
    ${(props) => props.theme.spacings.m};
  width: 100%;
  text-align: ${({ iconPosition }) =>
    iconPosition === Alignment.left
      ? "left"
      : iconPosition === Alignment.right
        ? "right"
        : "center"};
`;

export const IconLabelContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings.xs3};
`;

export const IconClose = styled(IconButton).attrs({
  icon: "IconX",
  variant: "line",
  size: "small",
})`
  position: absolute;
  top: ${(props) => props.theme.spacings.xs3};
  right: ${(props) => props.theme.spacings.xs};
`;

export const Title = styled(Typography).attrs({
  variant: "headline8",
})`
  font-weight: 700;
  color: ${(props) => props.theme.colors.neutral[10]};
  max-width: 420px;
`;

export const IconHeaderContainer = styled.div<{ iconPosition: Alignment }>`
  width: 100%;
  display: flex;
  justify-content: ${({ iconPosition }) =>
    iconPosition === Alignment.left
      ? "flex-start"
      : iconPosition === Alignment.right
        ? "flex-end"
        : "center"};
  > div {
    padding: ${(props) => props.theme.spacings.xs1};
  }
`;

export const IconLabel = styled(Typography).attrs({
  variant: "body3",
})`
  font-weight: 700;
  color: ${(props) => props.theme.colors.feedback.negative[40]};
`;

export const Subtitle = styled(Typography).attrs({
  variant: "body4",
})`
  font-weight: 400;
  color: ${(props) => props.theme.colors.neutral[50]};
  margin-top: ${(props) => props.theme.spacings.xs2};
  max-width: 420px;
`;

export const ModalFooterContainer = styled.div<{ showCancelButton: boolean }>`
  padding: ${(props) => props.theme.spacings.xs3}
    ${(props) => props.theme.spacings.m};
  border-top: 1px solid ${(props) => props.theme.colors.neutral[90]};
  display: flex;
  justify-content: ${({ showCancelButton }) =>
    showCancelButton ? "space-between" : "center"};
  align-items: center;
  border-bottom-left-radius: ${(props) => props.theme.borders.radius.l};
  border-bottom-right-radius: ${(props) => props.theme.borders.radius.l};
`;

export const CheckboxCheckedIcon = styled(ShapeIcon).attrs({
  size: "large",
  variant: "success",
  icon: "IconCircleCheck",
})`
  fill: ${(props) => props.theme.colors.feedback.success[40]};
`;

export const SuccessIconLabel = styled(Typography).attrs({
  variant: "body4",
})`
  font-weight: 700;
  color: ${(props) => props.theme.colors.feedback.success[40]};
  /* margin-top: ${(props) => props.theme.spacings.xs2}; */
  gap: ${(props) => props.theme.spacings.xs5};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconActionButtonContainer = styled.div`
  margin-left: ${(props) => props.theme.spacings.xs4};
`;
