import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import AppRouter from "./routes";

import { AppContextProvider } from "$pages/PendingApprovals/shared/context/AppContextProvider";
import "../src/i18n";
import { TrpcProvider } from "./api/client";

export default function Root() {
  return (
    <ThemeProvider>
      <TrpcProvider>
        <AppContextProvider>
          <AppRouter />
        </AppContextProvider>
      </TrpcProvider>
    </ThemeProvider>
  );
}
