import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import styled from "styled-components";

export const ReceiptIcon = styled(ShapeIcon)<{ empty: boolean }>`
  ${({ empty }) =>
    !empty &&
    `
    &:hover {
      transition: .2s;
      cursor: pointer;
      opacity: 0.8;
    }
  `}
`;

export const NumberReceiptsContainer = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-33px) translateX(24px);
  background-color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const NumberReceiptsDescription = styled(Typography)`
  font-size: 8px;
  color: ${({ theme }) => theme.colors.neutral[100]};
`;
