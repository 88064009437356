import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

export function getIconNameByCategoryDescription(
  description: string | undefined,
): IconTypes {
  switch (description) {
    case "Combustível":
      return "IconGasStation";
    case "Entrega/frete":
      return "IconTruckDelivery";
    case "Estacionamento":
      return "IconParking";
    case "Hospedagem":
      return "IconBuildingLighthouse";
    case "Manutenção e reparos":
      return "IconTools";
    case "Passagem aérea":
      return "IconPlane";
    case "Passagem rodoviária":
      return "IconBus";
    case "Pedágio":
      return "IconRoadSign";
    case "Refeição":
      return "IconToolsKitchen";
    case "Taxi/Uber":
      return "IconCar";
    case "Telefone e internet":
      return "IconPhone";
    case "Outros":
      return "IconDots";
    default:
      return "IconCreditCard";
  }
}
