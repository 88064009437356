import { Stepper, StepperProps } from "@flash-tecnologia/hros-web-ui-v2";

import {
  BreadcrumbItem,
  BreadcrumbPage,
} from "$components/shared/BreadcrumbPage";
import { useReimbursementsContext } from "$pages/PendingApprovals/ReimbursementsTable/context/ReimbursementsContext";
import { useDatePeriodContext } from "$pages/PendingApprovals/shared/context/DatePeriodContext";
import { useTransactionsContext } from "$pages/PendingApprovals/TransactionsTable/context/TransactionsContext";
import { DatePeriodMenu } from "../DatePeriodMenu";
import {
  Body,
  Container,
  Content,
  FlexContainer,
  Footer,
  PageHeaderContainer,
  Subtitle,
  Title,
  TitleAndSubtitle,
} from "./styled";

type HeaderPageProps = {
  title: string;
  subtitle?: string;
  breadcrumbItems?: BreadcrumbItem[];
  action?: React.ReactNode;
};

type BasePageProps = {
  children?: React.ReactNode;
  header?: HeaderPageProps;
  stepProps?: StepperProps;
  footer?: React.ReactNode;
  currentTab?: number;
};

export function BasePage({
  header,
  children,
  footer,
  stepProps,
  currentTab,
}: BasePageProps) {
  const { datePeriod, setDatePeriod } = useDatePeriodContext();
  const { methods: reimbursementMethods } = useReimbursementsContext();
  const { methods: transactionMethods } = useTransactionsContext();
  return (
    <Container>
      <Content hasFooter={Boolean(footer)}>
        {(header?.breadcrumbItems || stepProps) && (
          <FlexContainer>
            <BreadcrumbPage items={header?.breadcrumbItems || []} />
            {stepProps && <Stepper {...stepProps} />}
          </FlexContainer>
        )}
        {(header?.title || header?.subtitle) && (
          <PageHeaderContainer>
            <TitleAndSubtitle>
              <Title variant="headline7">{header?.title}</Title>
              <Subtitle variant="body3">{header?.subtitle}</Subtitle>
            </TitleAndSubtitle>
            {/* if currentTab is not balance-requests do not show date period button, temporary fix */}
            {currentTab !== 2 && (
              <DatePeriodMenu
                value={datePeriod}
                handleDateChange={(value) => {
                  setDatePeriod(value);
                  reimbursementMethods.setValue("pagination.pageNumber", 1);
                  transactionMethods.setValue("pagination.pageNumber", 1);
                }}
              />
            )}
          </PageHeaderContainer>
        )}
        <Body>{children}</Body>
      </Content>
      {footer && <Footer>{footer}</Footer>}
    </Container>
  );
}
