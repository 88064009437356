import { ColorVariant, SizeVariant } from "$themes";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled, { DefaultTheme } from "styled-components";

interface ShapeIconContainerProps {
  variant: ColorVariant;
  size: SizeVariant;
}

interface IconProps {
  variant: ColorVariant;
  size?: number;
  $foregroundColor?: string;
}

export const ShapeIconContainer = styled("div")<ShapeIconContainerProps>(
  ({ variant, size, ...props }) => ({
    display: "flex",
    width: "fit-content",
    height: "fit-content",
    padding: getSize(size).padding,
    borderRadius: props.theme.borders.radius.circular,
    backgroundColor: getVariantColor(props.theme.colors, variant).background,
  }),
);

export const Icon = styled(Icons)<IconProps>(
  ({ variant, theme, $foregroundColor }) => ({
    borderRadius: theme.borders.radius.circular,
    color: $foregroundColor
      ? $foregroundColor
      : getVariantColor(theme.colors, variant).foreground,
  }),
);

const getVariantColor = (
  colors: DefaultTheme["colors"],
  variant: ColorVariant,
) => {
  const shapeIconColors = {
    error: {
      foreground: colors.feedback.error[40],
      background: colors.feedback.error[90],
    },
    info: {
      foreground: colors.feedback.info[40],
      background: colors.feedback.info[90],
    },
    success: {
      foreground: colors.feedback.success[40],
      background: colors.feedback.success[90],
    },
    secondary: {
      foreground: colors.secondary[40],
      background: colors.secondary[95],
    },
    neutral: {
      foreground: colors.neutral[10],
      background: colors.neutral[90],
    },
  };

  return shapeIconColors[variant];
};

const getSize = (size: SizeVariant) => {
  const currentSize = {
    small: {
      padding: "8px",
    },
    medium: {
      padding: "14px",
    },
    large: {
      padding: "12px",
    },
    extra_small: {
      padding: "4px",
    },
    extra_small_2: {
      padding: "4px",
    },
    extra_small_3: {
      padding: "4px",
    },
    extra_small_4: {
      padding: "4px",
    },
    extra_small_5: {
      padding: "4px",
    },
    extra_large: {
      padding: "20px",
    },
    extra_large_2: {
      padding: "20px",
    },
    extra_large_3: {
      padding: "20px",
    },
    extra_large_4: {
      padding: "20px",
    },
    extra_large_5: {
      padding: "20px",
    },
  } as const;

  return currentSize[size];
};
