import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
  white-space: nowrap;
  flex-wrap: nowrap;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: ${({ theme }) => theme.spacings.xs5};
  .description-dot {
    width: 4px;
    height: 4px;
  }
`;

export const TextRow = styled(Typography)`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  color: ${(props) => props.theme.colors.neutral[20]};
`;
