import { external } from "@flash-hros/corporate-card";
import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { BasePage } from "$components/shared";
import { useTabPage } from "./hooks/useTabPage";
import { ROUTES_PENDING_APPROVALS } from "./routes";

const PendingApprovals = () => {
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.approvals",
  });

  const routesMap = ["", "reimbursements", "balance-requests"];
  const { currentTab, setParams } = useTabPage(
    `/${ROUTES_PENDING_APPROVALS.relativePath}`,
    routesMap,
  );

  const { label } = external.RequestBalanceApprovalTab();

  return (
    <BasePage
      currentTab={currentTab}
      header={{
        title: t("title"),
        breadcrumbItems: [
          {
            to: "/",
            label: t("breadcrumbItems.home"),
          },
          {
            to: "",
            label: t("breadcrumbItems.approvals"),
          },
        ],
      }}
    >
      <Tab
        tabItens={[
          {
            label: t("tabs.cardExpenses"),
            component: <Outlet />,
          },
          {
            label: t("tabs.reimbursements"),
            component: <Outlet />,
          },
          {
            label: label,
            component: <Outlet />,
          },
        ]}
        defaultTab={currentTab}
        onTabChanged={setParams}
      />
    </BasePage>
  );
};

export default PendingApprovals;
