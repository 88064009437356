import { ExpenseStatusTag } from "$components/Expense/ExpenseStatusTag";
import {
  Expense,
  ExpenseStatus,
  ExpenseType,
} from "$services/expense/types/expense/expense.types";
import { getValueWithCurrency } from "$utils/currency.utils";
import { formatDate } from "$utils/date.utils";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Flex } from "../Flex";
import { InfoCard } from "../InfoCard";
import { getIconNameByCategoryDescription } from "./getIconName.utils";
import { Container, Divisor, InfoModalCardContainer } from "./styled";

type ModalCardProps = {
  expense: Expense | null;
};

export function ModalCard({ expense }: ModalCardProps) {
  return (
    <>
      {expense && (
        <Container>
          <Flex
            justify="space-between"
            align="center"
            style={{ width: "100%" }}
            borderColor="neutral.90"
            padding="xs2"
          >
            <InfoCard
              title={expense?.category?.description ?? ""}
              iconName={getIconNameByCategoryDescription(
                expense?.category?.description,
              )}
              settings={{
                icon: {
                  size: 24,
                },
              }}
            />
            <Typography variant="body4">
              {getValueWithCurrency({
                value: expense?.amount,
                currencyPrefix: expense?.currency,
              })}
            </Typography>
          </Flex>
          <Divisor />

          <Flex
            justify="space-between"
            align="center"
            style={{ width: "100%", height: "86px" }}
            borderColor="neutral.90"
            padding="xs2"
          >
            <InfoModalCardContainer>
              <InfoCard
                title={expense?.employee?.name ?? ""}
                iconName="IconUser"
                settings={{
                  icon: {
                    size: 24,
                  },
                }}
                subtitle={formatDate(expense?.date ?? "")}
              />
              <ExpenseStatusTag
                status={expense?.status as ExpenseStatus}
                type={ExpenseType.REIMBURSEMENT}
              />
            </InfoModalCardContainer>
          </Flex>
        </Container>
      )}
    </>
  );
}
