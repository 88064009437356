import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { ComponentProps } from "react";

import { common } from "$frontend/src/i18n/locales/pt-br";
import {
  ExpenseStatus,
  ExpenseType,
} from "$services/expense/types/expense/expense.types";

export type ExpenseStatusTagProps = Readonly<{
  /* Expense status */
  status?: ExpenseStatus;
  /* Determines which set of status texts should be used based on type (default REIMBURSEMENT) */
  type?: ExpenseType;
}>;

const expenseStatusColors = {
  [ExpenseStatus.DRAFT]: "gray",
  [ExpenseStatus.REJECTED]: "red",
  [ExpenseStatus.REQUIRE_CHANGES]: "yellow",
  [ExpenseStatus.PENDING_APPROVAL]: "purple",
  [ExpenseStatus.PENDING_ACCOUNTING]: "blue",
  [ExpenseStatus.FINISHED]: "green",
} satisfies Record<ExpenseStatus, ComponentProps<typeof Tag>["variant"]>;

export function ExpenseStatusTag({
  status,
  type = ExpenseType.REIMBURSEMENT,
}: ExpenseStatusTagProps) {
  if (!status) {
    return <></>;
  }

  const getVariant = () => {
    if (status) {
      return expenseStatusColors[status];
    }
    return "gray";
  };

  return (
    <Tag
      as="div"
      hasLeftDotIcon
      variant={getVariant()}
      size="small"
      style={{ whiteSpace: "nowrap" }}
    >
      {
        common.expenseStatus[
          type === ExpenseType.TRANSACTION ? "CORPORATE_CARD" : "REIMBURSEMENT"
        ][status]
      }
    </Tag>
  );
}
