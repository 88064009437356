import { ReimbursementsProvider } from "$pages/PendingApprovals/ReimbursementsTable/context/ReimbursementsContext";
import { TransactionsProvider } from "$pages/PendingApprovals/TransactionsTable/context/TransactionsContext";
import { combineComponents } from "$utils/combine-components.utils";
import { DatePeriodProvider } from "./DatePeriodContext";

/**
 * Combines multiple context providers into a single provider for the app.
 */
const providers = [
  DatePeriodProvider,
  ReimbursementsProvider,
  TransactionsProvider,
];

export const AppContextProvider = combineComponents(...providers);
