import {
  Auth,
  getAccessTokenPayloadSync,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { z } from "zod";

import { dispatchToast } from "../utils/dispatch-events";

const AuthService = {
  useCompany,
  useEmployeeId,
};
export default AuthService;

/** Currently selected employeeId */
function useEmployeeId() {
  const accessToken = getAccessTokenPayloadSync();

  if (!accessToken?.employeeId) {
    forceSignOut();
    return null;
  }
  return accessToken.employeeId;
}

/** Currently selected company */
function useCompany() {
  const selectedCompany = useSelectedCompany();

  /** Currently selected company */
  const selectedCompanyParsed =
    selectedCompanySchema.safeParse(selectedCompany);

  if (!selectedCompanyParsed.success) {
    forceSignOut();
    return null;
  }

  return selectedCompanyParsed.data.selectedCompany;
}

const selectedCompanySchema = z.object({
  selectedCompany: z.object({
    /** Company id */
    id: z.string(),
    /** Company registration number */
    registrationNumber: z.string(),
  }),
});

function forceSignOut() {
  dispatchToast({
    content: "Sessão expirada",
    type: "error",
    description: "Por favor, realize o login novamente.",
  });
  // eslint-disable-next-line no-void
  void Auth.signOut();
}
