import {
  Button,
  CheckboxFilter,
  LinkButton,
  PillButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ExpenseFiltersFields, FiltersConfig } from "./filter-drawer.types";

import { CheckboxGroup } from "$components/shared/CheckboxGroup";
import { SliderField } from "$components/shared/SliderField";
import { useCategory } from "$frontend/src/hooks/useCategory";
import { common } from "$frontend/src/i18n/locales/pt-br";
import {
  ExpenseStatus,
  ExpenseType,
} from "$services/expense/types/expense/expense.types";
import { Card, CloseButton, Container, Content, Footer } from "./styled";

type ExpenseFilterDrawerProps = {
  filters: ExpenseFiltersFields;
  config: FiltersConfig;
  expenseType: ExpenseType;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (filters: ExpenseFiltersFields) => void;
  onFilterCountChange?: (count: number) => void;
};

const expenseStatusOptions = [
  ExpenseStatus.DRAFT,
  ExpenseStatus.REQUIRE_CHANGES,
  ExpenseStatus.PENDING_APPROVAL,
  ExpenseStatus.FINISHED,
  ExpenseStatus.REJECTED,
  ExpenseStatus.PENDING_ACCOUNTING,
] satisfies ExpenseStatus[];

const NO_CATEGORY = "UNCATEGORIZED";

export const ExpenseFilterDrawer = ({
  isOpen,
  onClose,
  config,
  expenseType,
  onSubmit,
  filters,
  onFilterCountChange,
}: ExpenseFilterDrawerProps) => {
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.approvals.expenseFilterDrawer",
  });
  const { data: categories, isFetching: isFetchingCategories } = useCategory();

  const [key, setKey] = useState(0);
  const methods = useForm<ExpenseFiltersFields>({
    defaultValues: {
      categoryIds: filters.categoryIds || [],
      expenseStatus: [ExpenseStatus.PENDING_APPROVAL],
      valueRange: filters.valueRange || { min: undefined, max: undefined },
    },
  });
  const control = methods.control;

  const resolvedConfig: Required<FiltersConfig> = {
    expenseStatus: false,
    transactionStatus: false,
    categoryIds: false,
    valueRange: false,
    ...config,
  };

  useEffect(() => {
    if (isOpen) {
      methods.reset({
        categoryIds: filters.categoryIds || [],
        expenseStatus:
          expenseType === ExpenseType.TRANSACTION
            ? filters.transactionStatus || []
            : filters.expenseStatus || [],
        valueRange: filters.valueRange,
      });
    }
  }, [isOpen, filters, methods, expenseType]);

  const handleFormSubmit = (data: ExpenseFiltersFields) => {
    const filters: ExpenseFiltersFields = {
      categoryIds: data.categoryIds,
      valueRange: data.valueRange,
    };

    if (expenseType === ExpenseType.TRANSACTION) {
      filters.transactionStatus = data.expenseStatus;
      filters.expenseStatus = [];
    } else {
      filters.expenseStatus = data.expenseStatus;
      filters.transactionStatus = [];
    }

    onSubmit(filters);
  };

  function onReset() {
    const defaultStatus = [ExpenseStatus.PENDING_APPROVAL];

    methods.reset({
      categoryIds: [],
      expenseStatus: defaultStatus,
      valueRange: {},
    });

    const filters: ExpenseFiltersFields = {
      categoryIds: [],
      valueRange: {
        min: undefined,
        max: undefined,
      },
      expenseStatus:
        expenseType === ExpenseType.REIMBURSEMENT ? defaultStatus : [],
      transactionStatus:
        expenseType === ExpenseType.TRANSACTION ? defaultStatus : [],
    };

    onSubmit(filters);
    setKey((prevKey) => prevKey + 1);
  }

  useEffect(() => {
    if (onFilterCountChange) {
      const count = getSelectedFiltersCount(filters);
      onFilterCountChange(count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  function getSelectedFiltersCount(filters: ExpenseFiltersFields): number {
    let count = 0;

    if ((filters.expenseStatus ?? [])?.length > 0) count += 1;
    if ((filters.transactionStatus ?? [])?.length > 0) count += 1;
    if ((filters.categoryIds ?? [])?.length > 0) count += 1;
    if (filters?.valueRange?.min || filters.valueRange?.max) count += 1;

    return count;
  }

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <Container>
        <Content>
          <CloseButton>
            <PillButton
              variant="default"
              size="small"
              icon="IconX"
              onClick={onClose}
            />
          </CloseButton>
          <Typography variant="headline7" color="neutral.20">
            {t("filters")}
          </Typography>

          {resolvedConfig.transactionStatus && (
            <Controller
              control={control}
              name="expenseStatus"
              render={({ field: { onChange, value } }) => (
                <Card>
                  <Typography variant="headline9" color="neutral.30">
                    {t("fields.transactionStatus.title")}
                  </Typography>
                  <CheckboxGroup
                    options={expenseStatusOptions.map((status) => ({
                      label:
                        expenseType === ExpenseType.REIMBURSEMENT
                          ? common.expenseStatus.REIMBURSEMENT[status]
                          : common.expenseStatus.CORPORATE_CARD[status],
                      value: status,
                    }))}
                    value={value}
                    onChange={onChange}
                    key={`transaction-status-${key}`}
                  />
                </Card>
              )}
            />
          )}

          {resolvedConfig.expenseStatus && (
            <Controller
              control={control}
              name="expenseStatus"
              render={({ field: { onChange, value } }) => (
                <Card>
                  <Typography variant="headline9" color="neutral.30">
                    {expenseType === ExpenseType.REIMBURSEMENT
                      ? t("fields.reimbursementsStatus.title")
                      : t("fields.corporateCardStatus.title")}
                  </Typography>
                  <CheckboxGroup
                    options={expenseStatusOptions.map((status) => ({
                      label:
                        expenseType === ExpenseType.REIMBURSEMENT
                          ? common.expenseStatus.REIMBURSEMENT[status]
                          : common.expenseStatus.CORPORATE_CARD[status],
                      value: status,
                    }))}
                    value={value}
                    onChange={onChange}
                    key={`expense-status-${key}`}
                  />
                </Card>
              )}
            />
          )}

          {resolvedConfig.categoryIds && !isFetchingCategories && (
            <Controller
              control={control}
              name="categoryIds"
              render={({ field: { onChange, value } }) => (
                <CheckboxFilter
                  title={t("fields.category.title")}
                  onAllSelection={(checked) => {
                    onChange(
                      checked
                        ? [
                            NO_CATEGORY,
                            ...(categories?.map((category) => category.id) ||
                              []),
                          ]
                        : [],
                    );
                  }}
                  options={[
                    {
                      label: t("noCategory"),
                      value: NO_CATEGORY,
                      checked: value?.includes(NO_CATEGORY) ?? false,
                      onChange: () => {
                        onChange(
                          value?.includes(NO_CATEGORY)
                            ? value.filter((v) => v !== NO_CATEGORY)
                            : [...(value || []), NO_CATEGORY],
                        );
                      },
                    },
                    ...(categories?.map((category) => ({
                      label: category.name,
                      value: category.id,
                      checked: value?.includes(category.id) ?? false,
                      onChange: () => {
                        onChange(
                          value?.includes(category.id)
                            ? value.filter((v) => v !== category.id)
                            : [...(value || []), category.id],
                        );
                      },
                    })) || []),
                  ]}
                  key={`category-${key}`}
                />
              )}
            />
          )}

          {resolvedConfig.valueRange && (
            <Controller
              control={control}
              name="valueRange"
              render={({ field: { onChange, value } }) => (
                <Card>
                  <Typography variant="headline9" color="neutral.30">
                    {t("amountRange.title")}
                  </Typography>
                  <SliderField
                    min={value?.min}
                    max={value?.max}
                    onChange={onChange}
                  />
                </Card>
              )}
            />
          )}
        </Content>

        <Footer>
          <LinkButton variant="default" onClick={onReset}>
            {t("reset")}
          </LinkButton>
          <Button
            minWidth="200px"
            variant="primary"
            onClick={methods.handleSubmit(handleFormSubmit)}
          >
            {t("submit")}
          </Button>
        </Footer>
      </Container>
    </Drawer>
  );
};
