import { DateRangePicker } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs2};
`;

export const MessageContainer = styled.div(({ theme }) => ({
  width: "400px",
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.colors.neutral[95],
  padding: `${theme.spacings.xs4} ${theme.spacings.xs3}`,
  borderRadius: theme.borders.radius.m,
  gap: theme.spacings.xs4,
}));

export const IconContainer = styled.div`
  display: flex;
`;

export const StyledDateRangePicker = styled(DateRangePicker)`
  .rdp-cell {
    width: 55px;
    height: 55px;
  }
  .rdp-day {
    width: 55px;
    height: 55px;
  }
  .rdp-day_selected_middle {
    width: 55px;
    height: 55px;
  }
  .rdp-table {
    width: 400px;
    max-width: 400px;
  }
`;
