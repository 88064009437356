import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs4};
  min-height: 184px;
  margin-top: ${(props) => props.theme.spacings.xs};

  svg {
    margin-bottom: ${(props) => props.theme.spacings.xs3};
  }
`;

export const Image = styled.img`
  width: 220px;
  height: auto;
  margin: 0 auto;
`;
