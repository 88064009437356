import { Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ImagesModal } from "$components/Expense/ExpenseReceipts/FileReader/ImagesModal";
import { Attachment } from "$services/expense/types/expense/expense.types";
import {
  NumberReceiptsContainer,
  NumberReceiptsDescription,
  ReceiptIcon,
} from "./styled";

type ExpenseReceiptsProps = {
  attachments: Attachment[];
  attachmentsNumber?: number;
  variant?: "primary" | "secondary";
};

export function ExpenseReceipts({
  attachments,
  attachmentsNumber = 0,
  variant = "primary",
}: ExpenseReceiptsProps) {
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.approvals.reimbursementsTable.expenseReceipts",
  });
  const attachmentsCount = attachments?.length || attachmentsNumber;
  const isEmpty = attachmentsCount === 0;
  const [showModal, setShowModal] = useState(false);

  function onShowModal() {
    if (attachments) {
      setShowModal((open) => !open);
    }
  }

  return (
    <>
      {variant === "primary" ? (
        <>
          <Tooltip
            arrow
            title={attachmentsCount === 0 ? t("attachmentsCount") : ""}
            placement="top"
          >
            <>
              <ReceiptIcon
                empty={isEmpty}
                name={isEmpty ? "IconReceiptOff" : "IconReceipt"}
                size={32}
                variant="neutral"
                onClick={isEmpty ? undefined : onShowModal}
              />
              {attachmentsCount > 1 && (
                <NumberReceiptsContainer>
                  <NumberReceiptsDescription
                    variant="overline"
                    weight={700}
                    style={{ lineHeight: 1 }}
                  >
                    {attachmentsCount}
                  </NumberReceiptsDescription>
                </NumberReceiptsContainer>
              )}
            </>
          </Tooltip>
        </>
      ) : (
        <ReceiptIcon
          empty={isEmpty}
          name={isEmpty ? "IconReceiptOff" : "IconReceipt"}
          size={32}
          variant="neutral"
        />
      )}

      {showModal && (
        <ImagesModal
          attachments={[...attachments]}
          loadingDescription={t("loadingReceipts")}
          onCloseModalClick={onShowModal}
          initialIndex={0}
        />
      )}
    </>
  );
}
