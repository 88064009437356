import styled from "styled-components";

export const Container = styled.div`
  width: 560px;
  height: 100%;
`;

export const Footer = styled.div`
  width: 100%;
  border-top: 1px;

  border-style: solid;
  border-color: ${(props) => props.theme.colors.neutral[90]};

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-left: ${(props) => props.theme.spacings.m};
  padding-right: ${(props) => props.theme.spacings.m};
  padding-top: ${(props) => props.theme.spacings.xs1};
  padding-bottom: ${(props) => props.theme.spacings.xs1};
`;

export const Content = styled.div`
  width: 100%;
  padding-left: ${(props) => props.theme.spacings.s};
  padding-right: ${(props) => props.theme.spacings.s};
  padding-top: ${(props) => props.theme.spacings.xs1};
  padding-bottom: ${(props) => props.theme.spacings.xs1};
`;

export const CloseButton = styled.div`
  position: absolute;
  right: ${(props) => props.theme.spacings.xs2};
  top: ${(props) => props.theme.spacings.xs2};
`;

export const Card = styled.div`
  padding-left: ${(props) => props.theme.spacings.xs2};
  padding-right: ${(props) => props.theme.spacings.xs2};
  padding-top: ${(props) => props.theme.spacings.xs2};
  padding-bottom: ${(props) => props.theme.spacings.xs2};

  margin-top: ${(props) => props.theme.spacings.xs1};
  margin-bottom: ${(props) => props.theme.spacings.xs1};

  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};

  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs2};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DatePickerStyled = styled.div``;
