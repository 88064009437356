export const REPROVE_REASONS = [
  {
    label: "Ausência de comprovante",
    value: "NO_RECEPIT",
  },
  {
    label: "Comprovante inválido",
    value: "INVALID_RECEIPT",
  },
  {
    label: "Prestação de contas fora do prazo",
    value: "OUT_OF_DATE",
  },
  {
    label: "Suspeita de fraude",
    value: "POSSIBLE_FRAUD",
  },
  {
    label: "Valor acima do previsto na política",
    value: "VALUE_EXCEEDS_LIMIT",
  },
  {
    label: "Outro Motivo",
    value: "OTHER",
  },
];
