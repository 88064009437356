export class ReceiptApiException extends Error {
  constructor(
    public statusCode: number,
    public code: string | undefined,
    public message: string,
  ) {
    super(
      `ReceiptApiException (${statusCode}):${Boolean(code) ? code : message}`,
    );
  }

  static async fromResponse(res: Response) {
    const body = await res.text();
    let code: string | undefined;
    try {
      const jsonBody = JSON.parse(body);
      code = jsonBody.message;
    } catch (e) {}
    return new ReceiptApiException(res.status, code, body);
  }
}
