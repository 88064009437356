import dayjs from "dayjs";
import { useMemo, useState } from "react";

import { formatDatePeriodToString } from "$utils/date.utils";
import {
  PillButton,
  ShapeIcon,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { DateRangeModal } from "../DateRangeModal/DateRangeModal";
import { Menu } from "../Menu/Menu";
import { Container, WarningBadgeContainer } from "./styled";

export const lastDay = dayjs().endOf("day").toDate();

export const mappedDays = {
  LAST_NINETY: dayjs().subtract(90, "days").startOf("day"),
  LAST_THIRTY: dayjs().subtract(30, "days").startOf("day"),
  LAST_MONTH: dayjs().startOf("month").startOf("day"),
  LAST_FIFTEEN: dayjs().subtract(15, "days").startOf("day"),
  LAST_SEVEN_DAYS: dayjs().subtract(7, "days").startOf("day"),
};

export type DateType =
  | "LAST_NINETY"
  | "LAST_THIRTY"
  | "LAST_MONTH"
  | "LAST_FIFTEEN"
  | "LAST_SEVEN_DAYS"
  | "CUSTOM";

export type DatePeriodState = {
  selected: DateType;
  startDate: Date;
  endDate: Date;
};

type DatePeriodMenuProps = {
  /** Selected date range value */
  value: DatePeriodState;
  /** Show alert badge on top right of the button  */
  showAlertBadge?: boolean;
  /** Alert badge tooltip (note that tooltip will only show if showAlertBadge is true) */
  alertBadgeTooltip?: string;
  /** Toggles loading state */
  isLoading?: boolean;
  /** Callback for when date is applied  */
  handleDateChange: (type: DatePeriodState) => void;
};

export function DatePeriodMenu({
  value,
  showAlertBadge,
  alertBadgeTooltip,
  handleDateChange: handleApplyMenuItem,
}: DatePeriodMenuProps) {
  const { t } = useTranslation("translations", {
    keyPrefix: "components.datePeriodMenu",
  });
  const menuOptions = useMemo(() => {
    return [
      {
        type: "LAST_NINETY",
        label: t("lastNinety"),
        description: formatDatePeriodToString(
          mappedDays.LAST_NINETY.toDate(),
          lastDay,
        ),
        selected: value.selected === "LAST_NINETY",
        onClick: () =>
          handleApplyMenuItem({
            selected: "LAST_NINETY",
            startDate: mappedDays.LAST_NINETY.toDate(),
            endDate: lastDay,
          }),
      },
      {
        type: "LAST_THIRTY",
        label: t("lastThirty"),
        description: formatDatePeriodToString(
          mappedDays.LAST_THIRTY.toDate(),
          lastDay,
        ),
        selected: value.selected === "LAST_THIRTY",
        onClick: () =>
          handleApplyMenuItem({
            selected: "LAST_THIRTY",
            startDate: mappedDays.LAST_THIRTY.toDate(),
            endDate: lastDay,
          }),
      },
      {
        type: "LAST_MONTH",
        label: t("lastMonth"),
        description: formatDatePeriodToString(
          mappedDays.LAST_MONTH.toDate(),
          lastDay,
        ),
        selected: value.selected === "LAST_MONTH",
        onClick: () =>
          handleApplyMenuItem({
            selected: "LAST_MONTH",
            startDate: mappedDays.LAST_MONTH.toDate(),
            endDate: lastDay,
          }),
      },

      {
        type: "LAST_FIFTEEN",
        label: t("lastFifteen"),
        description: formatDatePeriodToString(
          mappedDays.LAST_FIFTEEN.toDate(),
          lastDay,
        ),
        selected: value.selected === "LAST_FIFTEEN",
        onClick: () =>
          handleApplyMenuItem({
            selected: "LAST_FIFTEEN",
            startDate: mappedDays.LAST_FIFTEEN.toDate(),
            endDate: lastDay,
          }),
      },
      {
        type: "LAST_SEVEN_DAYS",
        label: t("lastSeven"),
        description: formatDatePeriodToString(
          mappedDays.LAST_SEVEN_DAYS.toDate(),
          lastDay,
        ),
        selected: value.selected === "LAST_SEVEN_DAYS",
        onClick: () =>
          handleApplyMenuItem({
            selected: "LAST_SEVEN_DAYS",
            startDate: mappedDays.LAST_SEVEN_DAYS.toDate(),
            endDate: lastDay,
          }),
      },
      {
        type: "CUSTOM",
        label: t("custom"),
        selected: value.selected === "CUSTOM",
        onClick: () => setIsModalOpen(true),
      },
    ];
  }, [t, value.selected, handleApplyMenuItem]);

  const label = useMemo(() => {
    if (value.selected !== "CUSTOM") {
      return menuOptions.find((option) => option.type === value.selected)
        ?.label;
    }

    return formatDatePeriodToString(value.startDate, value.endDate);
  }, [value.selected, value.startDate, value.endDate, menuOptions]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Container>
      <Menu options={menuOptions}>
        <PillButton
          variant="default"
          size="small"
          icon="IconCalendarTime"
          label={label}
          iconPosition="left"
        />
      </Menu>
      {showAlertBadge ? (
        <Tooltip title={alertBadgeTooltip}>
          <WarningBadgeContainer>
            <ShapeIcon
              name="IconAlertTriangle"
              size={24}
              variant="error"
              stroke="error"
            />
          </WarningBadgeContainer>
        </Tooltip>
      ) : undefined}
      <DateRangeModal
        initialDateRange={value}
        isOpen={isModalOpen}
        onApply={handleApplyMenuItem}
        onClose={() => setIsModalOpen(false)}
      />
    </Container>
  );
}
