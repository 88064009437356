/* eslint-disable react-hooks/exhaustive-deps */
import { Attachment } from "$services/expense/types/expense/expense.types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDisplayToast } from "$hooks/useDisplayToast";
import { ReceiptApiClient } from "$services/expense/receipt-api-client";

export const useInitImagesModal = (
  attachments: Attachment[] = [],
  propsReceiptsData?: string[],
  onCloseModalClick?: () => void,
) => {
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.approvals.reimbursementsTable.imagesModal",
  });
  const { displayToast } = useDisplayToast();
  const [loading, setLoading] = useState(false);
  const [receiptsSrc, setReceiptsSrc] = useState<
    Array<{ fileName: string; url: string }>
  >(
    (propsReceiptsData ?? []).map((url, index) => ({
      fileName: `file-${index}`,
      url,
    })),
  );

  useEffect(() => {
    onInit();
  }, []);

  async function onInit() {
    const noAttachments = attachments.length === 0;
    const alreadyHasData = receiptsSrc.length > 0;
    if (noAttachments || alreadyHasData) {
      return;
    }

    setLoading(true);

    try {
      const loadedSrc = await Promise.all(
        attachments.map(async (attachment) => ({
          fileName: attachment.fileName,
          url: URL.createObjectURL(await ReceiptApiClient.getFile(attachment)),
        })),
      );
      setReceiptsSrc(loadedSrc);
    } catch (e) {
      displayToast({
        type: "error",
        title: t("toasts.failedLoadingReceipts.title"),
        description: t("toasts.failedLoadingReceipts.description"),
      });
      onCloseModalClick?.();
    } finally {
      setLoading(false);
    }
  }

  return { loading, receiptsSrc };
};
