import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";
import React, { useState } from "react";

import { Flex } from "$components/shared/Flex/Flex";
import {
  Button,
  ButtonContainer,
  Dots,
  DotsContainer,
  ItemContainer,
} from "./styled";

export type CarouselProps = {
  /**
   * List of items to display
   * @default 'exemple images'
   */
  items: React.ReactNode[];

  /**
   * If true presents of the points
   *
   */
  showDots?: boolean;

  /**
   * Sets the initial index value
   *
   */
  initialIndex?: number;

  /**
   * Sets custom arrows
   *
   */
  customArrows?: {
    left: IconTypes;
    right: IconTypes;
  };

  /**
   * get current index
   *
   */
  getCurrentIndex?(currentIndex: number): void;
};

export function Carousel({
  items,
  showDots = true,
  initialIndex = 0,
  customArrows,
  getCurrentIndex,
}: CarouselProps) {
  const [currentItemIndex, setCurrentItemIndex] = useState(initialIndex);

  const hasPrevImage = currentItemIndex > 0 && items?.length > 1;
  const hasNextImage = currentItemIndex < items?.length - 1;
  const _showDots = showDots && items?.length > 1;

  const handleIndexChange = (increment: number) => {
    getCurrentIndex && getCurrentIndex(increment);
    setCurrentItemIndex((prevIndex) => prevIndex + increment);
  };

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      width="100%"
      height="100%"
    >
      <Flex justify="center" align="center" width="100%" height="100%">
        <ButtonContainer>
          {hasPrevImage && (
            <Button
              icon={customArrows ? customArrows.left : "IconArrowLeft"}
              variant="default"
              size="medium"
              onClick={() => handleIndexChange(-1)}
            />
          )}
        </ButtonContainer>
        <Flex padding="xs">
          <ItemContainer key={`item-carousel-${currentItemIndex}`}>
            {items?.[currentItemIndex]}
          </ItemContainer>
        </Flex>
        <ButtonContainer>
          {hasNextImage && (
            <Button
              icon={customArrows ? customArrows.right : "IconArrowRight"}
              variant="default"
              size="medium"
              onClick={() => handleIndexChange(1)}
            />
          )}
        </ButtonContainer>
      </Flex>
      {_showDots && (
        <DotsContainer>
          {items.map((_, index) => (
            <Dots
              active={index == currentItemIndex}
              key={`item-carousel-dots-${index}`}
              onClick={() => setCurrentItemIndex(index)}
            />
          ))}
        </DotsContainer>
      )}
    </Flex>
  );
}
