export const CATEGORY_STATUS = ["ACTIVE", "INACTIVE"] as const;

export enum ExpenseType {
  REIMBURSEMENT = "REIMBURSEMENT",
  CORPORATE_CARD = "CORPORATE_CARD",
  TRANSACTION = "TRANSACTION",
}

export enum ExpensePaymentMethod {
  CASH = "CASH",
  PIX = "PIX",
  CREDIT_CARD = "CREDIT_CARD",
  DEBIT_CARD = "DEBIT_CARD",
  FLASH_CARD = "FLASH_CARD",
}

export enum ExpenseTransactionCategory {
  REFEICAO = "refeição",
  ALIMENTACAO = "alimentação",
  MOBILIDADE = "mobilidade",
  SAUDE = "saúde",
  ESTAR = "bem estar",
  EDUCACAO = "educação",
  CULTURA = "cultura",
  CONVENIENCIA = "conveniência",
}

export enum ExpenseTransactionCardType {
  PLASTIC = "PLASTIC",
  VIRTUAL = "VIRTUAL",
}

export enum ExpenseStatus {
  /** Rascunho (Pendente) */
  DRAFT = "DRAFT",
  /** Em aprovação (Somente quando tem fluxo de aprovação) */
  PENDING_APPROVAL = "PENDING_APPROVAL",
  /** Em pagamento */
  PENDING_ACCOUNTING = "PENDING_ACCOUNTING",
  /** Reembolsado */
  FINISHED = "FINISHED",
  /** Em revisão */
  REQUIRE_CHANGES = "REQUIRE_CHANGES",
  /** Reprovado */
  REJECTED = "REJECTED",
}

export enum PolicyStatusType {
  INSIDE_POLITICS = "INSIDE_POLITICS",
  OUTSIDE_POLITICS = "OUTSIDE_POLITICS",
}

export enum TransactionMovement {
  OPEN_LOOP_PAYMENT = "OPEN_LOOP_PAYMENT",
  CLOSED_LOOP_PAYMENT = "CLOSED_LOOP_PAYMENT",
  BILLET_PAYMENT = "BILLET_PAYMENT",
  CASH_IN_PIX = "CASH_IN_PIX",
  CASH_IN_TED = "CASH_IN_TED",
  CASH_OUT_PIX = "CASH_OUT_PIX",
  CASH_OUT_TED = "CASH_OUT_TED",
  DEPOSIT = "DEPOSIT",
  COMPANY_WITHDRAW = "COMPANY_WITHDRAW",
  EMPLOYEE_WITHDRAW = "EMPLOYEE_WITHDRAW",
}

export enum TransactionStatus {
  AUTHORIZED = "AUTHORIZED",
  COMPLETED = "COMPLETED",
  SETTLED = "SETTLED",
  CANCELED = "CANCELED",
  REVERTED = "REVERTED",
  PROCESSING = "PROCESSING",
}

export enum TransactionType {
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
}

export type Attachment = {
  fileName: string;
  s3Path: string;
};

export type CategoryStatus = (typeof CATEGORY_STATUS)[number];

export type Category = {
  id: string;
  name: string;
  externalId?: string;
  status: CategoryStatus;
  isDefault: boolean;
};

export type CostCenter = {
  id: string;
  code: string;
  name: string;
};

export type Employee = {
  name: string;
  email?: string;
};

export type Establishment = {
  source: "PLACES_API" | "OCR";
  placeId: string;
  name: string;
  description: string;
  address: PlaceAddress;
};

export type ExpenseCategory = {
  nanoId: string;
  description: string;
};

export type ExpenseTransaction = {
  id: string;
  description: string;
  cardLastDigits?: string;
  category?: ExpenseTransactionCategory;
  cardType?: ExpenseTransactionCardType;
  status: TransactionStatus;
  date: string;
  amount: number;
  currency?: string;
  type: TransactionType;
  movement: TransactionMovement;
  isLegacyTransaction: boolean;
};

export type PlaceAddress = {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
};

export type Expense = {
  id: string;
  amount: number;
  status: ExpenseStatus;
  attachments?: Attachment[];
  accountingBy?: string;
  category?: ExpenseCategory;
  comments?: string;
  accountingComments?: string;
  companyId: string;
  costCenter?: CostCenter | null;
  currency?: string;
  //NOTE: date is string due to JSON serialization
  date: string;
  employeeId: string;
  employee?: Employee;
  paymentMethod?: ExpensePaymentMethod;
  referenceId?: string;
  reportId?: string;
  type: ExpenseType;
  transaction?: ExpenseTransaction;
  /** establishment is null when expense type is `CORPORATE_CARD`,
  prioritizing field transaction.description instead. */
  establishment?: Establishment;
};

type BaseExpense = {
  id: string;
  amount: number;
  status: ExpenseStatus;
  attachments?: Attachment[];
  accountingBy?: string;
  category?: ExpenseCategory;
  comments?: string;
  accountingComments?: string;
  companyId: string;
  costCenter?: CostCenter | null;

  currency?: string;
  date: string;
  employeeId: string;
  employee?: Employee;
  paymentMethod?: ExpensePaymentMethod;
  referenceId?: string;
  reportId?: string;
  transaction?: ExpenseTransaction;
};

export type CorporateCardExpense = BaseExpense & {
  type: ExpenseType.CORPORATE_CARD;
};

export type ReimbursementExpense = BaseExpense & {
  type: ExpenseType.REIMBURSEMENT;
  establishment: Establishment;
};
