import {
  IconsProps,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Container, Image } from "./styled";

type EmptyListProps = {
  title: string;
  description: string;
  iconName?: IconsProps["name"];
  imageUrl?: string;
};

export function EmptyList({
  iconName,
  imageUrl,
  title,
  description,
}: EmptyListProps) {
  return (
    <Container>
      {imageUrl && <Image src={imageUrl} aria-label={title} />}

      {iconName && (
        <ShapeIcon
          variant="neutral"
          // TODO: send IconZoomCancel as iconName
          name={iconName}
          size={56}
          stroke="neutral"
        />
      )}
      <Typography variant="headline8" color="neutral.40">
        {title}
      </Typography>
      <Typography variant="body4" color="neutral.40">
        {description}
      </Typography>
    </Container>
  );
}
