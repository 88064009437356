import { PendingApprovalsPage } from "$pages/PendingApprovals/routes";
import { BrowserRouter, Routes as RouterRoutes } from "react-router-dom";

function AppRouter() {
  return (
    <BrowserRouter>
      <RouterRoutes>{PendingApprovalsPage}</RouterRoutes>
    </BrowserRouter>
  );
}

export default AppRouter;
