export const services = {
  approvals: {
    development: "http://localhost:4200",
    staging: "https://approvals-server.private.flashapp.dev",
    production: "https://approvals-server.us.private.flashapp.services",
  },
  expenseLifecycle: {
    development: "https://expense-core-bff.private.flashapp.dev/v1/bff",
    staging: "https://expense-core-bff.private.flashapp.dev/v1/bff",
    production: "https://expense-core-bff.us.flashapp.services/v1/bff",
  },
} as const;
