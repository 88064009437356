import { PillButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled, { keyframes } from "styled-components";

interface DotsProps {
  active?: boolean;
}

const itemAnimation = keyframes`
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
  0% {
    opacity: 0%;
  }
`;

export const ItemContainer = styled.div`
  overflow: hidden;
  border-radius: ${(props) => props.theme.borders.radius.l};
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${itemAnimation} 1s;
`;

export const DotsContainer = styled.div`
  display: flex;
  width: fit-content;
`;

export const Dots = styled.div<DotsProps>`
  height: 5px;
  width: 20px;
  margin: 0 ${(props) => props.theme.spacings.xs5};
  border-radius: ${(props) => props.theme.borders.radius.xl};
  cursor: pointer;
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.neutral[50]
      : props.theme.colors.neutral[90]};
`;

export const ButtonContainer = styled.button`
  display: flex;
  width: 48px;
  height: 48px;
`;

export const Button = styled(PillButton)`
  &:hover {
    opacity: 0.8;
  }
`;
