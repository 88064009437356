import {
  ExpenseStatus,
  ReimbursementExpense,
} from "$services/expense/types/expense/expense.types";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { createContext, useContext, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

export type ReimbursementsFilters = {
  status: ExpenseStatus[];
  employeeName?: string;
  categoryNanoIds?: string[];
  valueRange?: {
    min?: number;
    max?: number;
  };
  pagination: PaginationState;
};

export type ReimbursementsState = {
  filters: ReimbursementsFilters;
  selectedExpense: ReimbursementExpense | null;
  isApproveModalOpen: boolean;
  isRequestReviewModalOpen: boolean;
  isReproveModalOpen: false;
};

const defaultValues: ReimbursementsState = {
  filters: {
    status: [ExpenseStatus.PENDING_APPROVAL],
    categoryNanoIds: [],
    valueRange: undefined,
    employeeName: undefined,
    pagination: { pageNumber: 1, pageSize: 10 },
  },
  selectedExpense: null,
  isApproveModalOpen: false,
  isRequestReviewModalOpen: false,
  isReproveModalOpen: false,
};

export type ISessionProviderProps = {
  children: React.ReactNode;
};

type ReimbursementsContextProps = {
  methods: UseFormReturn<ReimbursementsFilters>;
  isApproveModalOpen: boolean;
  setIsApproveModalOpen: (isOpen: boolean) => void;
  isRequestReviewModalOpen: boolean;
  setIsRequestReviewModalOpen: (isOpen: boolean) => void;
  isReproveModalOpen: boolean;
  setIsReproveModalOpen: (isOpen: boolean) => void;
  selectedExpense: ReimbursementExpense | null;
  setSelectedExpense: (selectedExpense: ReimbursementExpense) => void;
};

const ReimbursementsContext = createContext<
  ReimbursementsContextProps | undefined
>(undefined);

export const ReimbursementsProvider = ({ children }: ISessionProviderProps) => {
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(
    defaultValues.isApproveModalOpen,
  );

  const [isRequestReviewModalOpen, setIsRequestReviewModalOpen] =
    useState<boolean>(defaultValues.isRequestReviewModalOpen);

  const [isReproveModalOpen, setIsReproveModalOpen] = useState<boolean>(
    defaultValues.isRequestReviewModalOpen,
  );

  const [selectedExpense, setSelectedExpense] =
    useState<ReimbursementExpense | null>(defaultValues.selectedExpense);

  const methods = useForm<ReimbursementsFilters>({
    defaultValues: defaultValues.filters,
  });

  return (
    <ReimbursementsContext.Provider
      value={{
        methods,
        isApproveModalOpen,
        setIsApproveModalOpen,
        isRequestReviewModalOpen,
        setIsRequestReviewModalOpen,
        selectedExpense,
        setSelectedExpense,
        isReproveModalOpen,
        setIsReproveModalOpen,
      }}
    >
      {children}
    </ReimbursementsContext.Provider>
  );
};

export const useReimbursementsContext = () => {
  const context = useContext(ReimbursementsContext);
  if (!context) {
    throw new Error(
      "useReimbursementsContext must be used within a ReimbursementsProvider",
    );
  }
  return context;
};
