import {
  DatePeriodState,
  lastDay,
  mappedDays,
} from "$components/shared/DatePeriodMenu";
import { createContext, useContext, useState } from "react";

const defaultValues: DatePeriodState = {
  selected: "LAST_NINETY",
  startDate: mappedDays.LAST_NINETY.toDate(),
  endDate: lastDay,
};

type DatePeriodContextProps = {
  datePeriod: DatePeriodState;
  setDatePeriod: (period: DatePeriodState) => void;
  reset: () => void;
};

const DatePeriodContext = createContext<DatePeriodContextProps | undefined>(
  undefined,
);

export const DatePeriodProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [datePeriod, setDatePeriod] = useState<DatePeriodState>(defaultValues);
  const reset = () => {
    setDatePeriod(defaultValues);
  };

  return (
    <DatePeriodContext.Provider
      value={{
        datePeriod,
        setDatePeriod,
        reset,
      }}
    >
      {children}
    </DatePeriodContext.Provider>
  );
};

export const useDatePeriodContext = () => {
  const context = useContext(DatePeriodContext);
  if (!context) {
    throw new Error(
      "useDatePeriodContext must be used within a DatePeriodProvider",
    );
  }
  return context;
};
