import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 134px;
  border-radius: ${({ theme }) => theme.borders.radius.l};
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
  gap: 2px;
  flex-wrap: wrap;
`;

export const Divisor = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${(props) => props.theme.colors.neutral[90]};
`;

export const InfoModalCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) =>
    `${props.theme.spacings.xs4} ${props.theme.spacings.xs3}`};
  width: 100%;
  height: 54px;
  background-color: ${(props) => props.theme.colors.neutral[95]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  margin: ${(props) => props.theme.spacings.xs3} 0;
`;
