import { useMemo } from "react";

import { trpc } from "$frontend/src/api/client";
import { useDatePeriodContext } from "$pages/PendingApprovals/shared/context/DatePeriodContext";
import { ExpenseStatus } from "$services/expense/types/expense/expense.types";
import { SearchExpensesQuery } from "server/src/services/expense-lifecycle-service";
import { useTransactionsContext } from "../context/TransactionsContext";

export function useTransactionsTableData() {
  const { datePeriod } = useDatePeriodContext();
  const { methods } = useTransactionsContext();
  const { watch } = methods;

  const formValues = watch();

  const transactionsQuery = useMemo(() => {
    const query: SearchExpensesQuery = {
      initialDate: datePeriod.startDate.toISOString(),
      endDate: datePeriod.endDate.toISOString(),
      pageNumber: formValues.pagination.pageNumber,
      pageSize: formValues.pagination.pageSize,
      status: formValues.status || [ExpenseStatus.PENDING_APPROVAL],
      categoryIds: formValues.categoryNanoIds || [],
      minAmount: formValues.valueRange?.min,
      maxAmount: formValues.valueRange?.max,
      employeeName: formValues.employeeName || "",
    };

    return query;
  }, [formValues, datePeriod]);

  const { data, isFetching, refetch } = trpc.transaction.approvals.useQuery(
    transactionsQuery,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  return {
    data,
    isLoading: isFetching,
    totalCount: data?.totalCount ?? 0,
    totalPages: data?.totalPages ?? 0,
    refetch,
  };
}
