/**
 * Converts a number in cents to brazilian format
 *
 * @param value amount in cents (e.g: 10000)
 * @param noPrefix boolean to indicate if the preceding _R$_ should be on the returned string
 * @returns 'R$ 100,00' for the example _value_
 */

export function toCurrencyString(value: number, noPrefix = false): string {
  const prefix = noPrefix ? "" : "R$";
  if (isNaN(Number(value))) return "R$ 0,00";

  const currency = `${prefix} ${(value / 100).toFixed(2).replace(".", ",")}`;
  return currency.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export interface GetValueWithCurrencyProps {
  value?: number;
  currencyPrefix?: string;
  locale?: string;
}

export function getValueWithCurrency({
  value = 0,
  currencyPrefix = "BRL",
  locale = "pt-BR",
}: GetValueWithCurrencyProps) {
  value = Number((value / 100).toFixed(2));

  return value?.toLocaleString(locale, {
    style: "currency",
    currency: currencyPrefix,
  });
}

export function centsToMoney(cents: number | undefined) {
  // TODO: validate this function
  return Number(((cents ?? 0) / 100).toFixed(2));
}
