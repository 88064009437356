import {
  Icons,
  IconsProps,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import { Container } from "./styled";

type MenuItemProps = {
  label: string;
  description?: string;
  selected?: boolean;
  icon?: IconsProps["name"];
};

export function MenuItem(props: MenuItemProps) {
  const { colors } = useTheme();

  return (
    <Container selected={props.selected ?? false}>
      {props.icon && (
        <Icons
          name={props.icon}
          color={props.selected ? colors.secondary[40] : colors.neutral[30]}
          size={16}
        />
      )}

      <div>
        <Typography
          variant="body4"
          weight={600}
          color={props.selected ? "secondary.40" : "neutral.30"}
        >
          {props.label}
        </Typography>

        {props.description && (
          <Typography
            variant="caption"
            weight={600}
            color={props.selected ? "secondary.40" : "neutral.40"}
          >
            {props.description}
          </Typography>
        )}
      </div>

      {props.selected && (
        <Icons color={colors.secondary[40]} name="IconCircleCheck" size={16} />
      )}
    </Container>
  );
}
