import {
  CorporateCardExpense,
  ExpenseStatus,
} from "$services/expense/types/expense/expense.types";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { createContext, useContext, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

export type TransactionsFilters = {
  status: ExpenseStatus[];
  employeeName?: string;
  categoryNanoIds?: string[];
  valueRange?: {
    min?: number;
    max?: number;
  };
  pagination: PaginationState;
};

export type TransactionsState = {
  filters: TransactionsFilters;
  selectedExpense: CorporateCardExpense | null;
  isApproveModalOpen: boolean;
  isRequestReviewModalOpen: boolean;
  isReproveModalOpen: false;
};

const defaultValues: TransactionsState = {
  filters: {
    status: [ExpenseStatus.PENDING_APPROVAL],
    categoryNanoIds: [],
    valueRange: undefined,
    employeeName: undefined,
    pagination: { pageNumber: 1, pageSize: 10 },
  },
  selectedExpense: null,
  isApproveModalOpen: false,
  isRequestReviewModalOpen: false,
  isReproveModalOpen: false,
};

export type ISessionProviderProps = {
  children: React.ReactNode;
};

type TransactionsContextProps = {
  methods: UseFormReturn<TransactionsFilters>;
  isApproveModalOpen: boolean;
  setIsApproveModalOpen: (isOpen: boolean) => void;
  isRequestReviewModalOpen: boolean;
  setIsRequestReviewModalOpen: (isOpen: boolean) => void;
  isReproveModalOpen: boolean;
  setIsReproveModalOpen: (isOpen: boolean) => void;
  selectedExpense: CorporateCardExpense | null;
  setSelectedExpense: (selectedExpense: CorporateCardExpense) => void;
};

const TransactionsContext = createContext<TransactionsContextProps | undefined>(
  undefined,
);

export const TransactionsProvider = ({ children }: ISessionProviderProps) => {
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(
    defaultValues.isApproveModalOpen,
  );

  const [isRequestReviewModalOpen, setIsRequestReviewModalOpen] =
    useState<boolean>(defaultValues.isRequestReviewModalOpen);

  const [isReproveModalOpen, setIsReproveModalOpen] = useState<boolean>(
    defaultValues.isRequestReviewModalOpen,
  );

  const [selectedExpense, setSelectedExpense] =
    useState<CorporateCardExpense | null>(defaultValues.selectedExpense);

  const methods = useForm<TransactionsFilters>({
    defaultValues: defaultValues.filters,
  });

  return (
    <TransactionsContext.Provider
      value={{
        methods,
        isApproveModalOpen,
        setIsApproveModalOpen,
        isRequestReviewModalOpen,
        setIsRequestReviewModalOpen,
        selectedExpense,
        setSelectedExpense,
        isReproveModalOpen,
        setIsReproveModalOpen,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
};

export const useTransactionsContext = () => {
  const context = useContext(TransactionsContext);
  if (!context) {
    throw new Error(
      "useTransactionsContext must be used within a TransactionsProvider",
    );
  }
  return context;
};
