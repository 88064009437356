import styled from "styled-components";

export const Container = styled.div<{ selected: boolean }>`
  display: flex;
  gap: ${(props) => props.theme.spacings.xs4};
  padding: ${(props) => props.theme.spacings.xs4}
    ${(props) => props.theme.spacings.xs3};
  align-items: center;
  width: 100%;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.secondary[99] : undefined};
`;
