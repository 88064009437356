import {
  Button,
  ButtonProps,
  Icons,
  LinkButton,
  ShapeIcon,
} from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import { useTranslation } from "react-i18next";

import { Alignment } from "$utils/enum.utils";
import {
  IconActionButtonContainer,
  IconClose,
  IconLabelContainer,
  ModalBodyContainer,
  ModalContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  StyledActionModal,
  Subtitle,
  SuccessIconLabel,
  Title,
} from "./styled";

export interface SuccessActionModalProps {
  /**
   * if 'true' open the modal
   */
  open: boolean;

  /**
   * if 'true' the button shows loading status
   */
  isLoading?: boolean;

  /**
   * Modal content
   */
  children?: React.ReactNode;

  /**
   * Icon Label header
   *
   */
  headerIconLabel?: string;
  /**
   * Flag when is a batch approval
   *
   */
  isBatchApproval?: boolean;
  /**
   * Title header
   *
   */
  headerTitle?: string;

  /**
   * Subtitle header
   *
   */
  headerSubtitle?: string;

  /**
   * Text or custom item in header
   *
   */
  headerCustom?: React.ReactNode;

  /**
   * Text or custom item in footer
   *
   */
  footerCustom?: React.ReactNode;

  /**
   * Confirmation button custom text or item
   *
   */
  labelButtonAction?: React.ReactNode;

  /**
   * Icon Action Typography
   *
   */
  iconActionButton?: React.ReactNode;

  /**
   * Cancel button custom text or item
   *
   */
  labelButtonCancel?: React.ReactNode;

  /**
   * if 'true' disable button action
   */
  isActionDisabled?: boolean;

  /**
   * Header icon position
   *
   */
  iconPosition?: Alignment;

  /**
   * Show Cancel Typography
   *
   */
  showCancelButton?: boolean;

  /**
   * Custom header icon component
   */
  headerIcon?: React.ReactNode;

  /**
   * If header icon is a small or shape icon
   */
  headerIconMode?: "shape" | "small";

  /**
   * Custom action button properties
   */
  actionButtonProps?: ButtonProps;

  /**
   * Cancel event
   *
   */
  onCancelClick?(): void;

  /**
   * Close event
   *
   */
  onCloseClick?(): void;

  /**
   * Action event
   *
   */
  onActionClick?(): void;

  /**
   * Class name
   */
  className?: string;

  /**
   * show action button
   */
  showActionButton?: boolean;

  /**
   * show close button
   */
  showCloseButton?: boolean;
}

export const SuccessActionModal = ({
  open,
  isLoading,
  children,
  headerIconLabel,
  isBatchApproval = false,
  headerTitle,
  headerSubtitle,
  headerCustom,
  footerCustom,
  labelButtonAction,
  iconActionButton,
  labelButtonCancel,
  isActionDisabled,
  iconPosition = Alignment.center,
  showCancelButton = true,
  onCancelClick,
  onCloseClick,
  onActionClick,
  actionButtonProps,
  className,
  showActionButton = true,
  showCloseButton = false,
}: SuccessActionModalProps) => {
  const { t } = useTranslation("translations", {
    keyPrefix: "components.actionModal",
  });

  return (
    <StyledActionModal open={open} onClose={onCloseClick} className={className}>
      <ModalContainer>
        <ModalHeaderContainer iconPosition={iconPosition}>
          {Boolean(headerCustom) ? (
            headerCustom
          ) : (
            <>
              <IconClose
                variant="line"
                size="medium"
                onClick={onCloseClick || onCancelClick}
              />

              <IconLabelContainer>
                {Boolean(headerIconLabel) && (
                  <SuccessIconLabel variant="body4">
                    {isBatchApproval ? (
                      <div>
                        <ShapeIcon
                          name="IconCurrencyDollar"
                          size={48}
                          stroke="success"
                          variant="success"
                        />
                        {headerIconLabel}
                      </div>
                    ) : (
                      <>
                        <Icons name="IconCircleCheck" size={16} />
                        {headerIconLabel}
                      </>
                    )}
                  </SuccessIconLabel>
                )}
              </IconLabelContainer>

              {Boolean(headerTitle) && (
                <Title variant="headline8">{headerTitle}</Title>
              )}
              {Boolean(headerSubtitle) && (
                <Subtitle variant="body4">{headerSubtitle}</Subtitle>
              )}
            </>
          )}
        </ModalHeaderContainer>
        {Boolean(children) && (
          <ModalBodyContainer className="success-action-modal-body">
            {children}
          </ModalBodyContainer>
        )}
        {footerCustom !== null && (
          <ModalFooterContainer showCancelButton={showCancelButton}>
            {Boolean(footerCustom) ? (
              footerCustom
            ) : (
              <>
                {showCancelButton && (
                  <div>
                    <LinkButton
                      variant="neutral"
                      onClick={onCancelClick || onCloseClick}
                    >
                      {labelButtonCancel || t("cancel")}
                    </LinkButton>
                  </div>
                )}
                <div>
                  {showActionButton && (
                    <Button
                      loading={isLoading}
                      disabled={isActionDisabled}
                      onClick={onActionClick}
                      variantType="success"
                      size="large"
                      variant="primary"
                      {...actionButtonProps}
                    >
                      {labelButtonAction || t("confirm")}
                      {Boolean(iconActionButton) && (
                        <IconActionButtonContainer>
                          {iconActionButton}
                        </IconActionButtonContainer>
                      )}
                    </Button>
                  )}
                </div>
                <>
                  {showCloseButton && (
                    <Button
                      onClick={onCancelClick || onCloseClick}
                      size="large"
                      variant="secondary"
                      variantType="neutral"
                    >
                      {t("closeButton")}
                    </Button>
                  )}
                </>
              </>
            )}
          </ModalFooterContainer>
        )}
      </ModalContainer>
    </StyledActionModal>
  );
};
