import {
  PillButton,
  SelectField,
  Table,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ApprovalsSurvey } from "$components/Expense";
import { SurveyActionType } from "$components/Expense/ApprovalsSurvey/survey.types";
import { Flex, ModalCard, TextArea } from "$components/shared";
import {
  DangerActionModal,
  ErrorActionModal,
  SuccessActionModal,
} from "$components/shared/ActionModal";
import { EmptyList } from "$components/Table/EmptyList";
import { trpc } from "$frontend/src/api/client";
import { useDebounce } from "$frontend/src/hooks/useDebounce";
import {
  ExpenseStatus,
  ExpenseType,
} from "$services/expense/types/expense/expense.types";
import { Alignment } from "$utils/enum.utils";
import { ExpenseFilterDrawer } from "../ExpenseFilterDrawer";
import { ExpenseFiltersFields } from "../ExpenseFilterDrawer/filter-drawer.types";
import { REPROVE_REASONS } from "../shared/reprove-reason.utils";
import { useTransactionsContext } from "./context/TransactionsContext";
import { useTransactionsTableData } from "./data/useTransactionsTableData";
import { ModalItemsContainer } from "./styled";
import { useTransactionTableSetup } from "./table/useTransactionTableSetup";

const MAX_CHAR_REQUEST_REVIEW_COMMENT = 500;
const MIN_CHAR_REQUEST_REVIEW_COMMENT = 15;
const DEBOUNCE_TIME = 300;

export function TransactionsTable() {
  const pageSizes = [5, 10, 15, 25, 50];

  const [justification, setJustification] = useState("");
  const [reason, setReason] = useState("");

  // Modal Approve
  const [showApproveErrorModal, setShowApproveErrorModal] = useState(false);
  const [showApproveSuccessModal, setShowApproveSuccessModal] = useState(false);
  // Modal Request Review
  const [showRequestReviewSuccessModal, setShowRequestReviewSuccessModal] =
    useState(false);
  const [showRequestReviewErrorModal, setShowRequestReviewErrorModal] =
    useState(false);
  // Modal Reprove
  const [showReproveSuccessModal, setShowReproveSuccessModal] = useState(false);
  const [showReproveErrorModal, setShowReproveErrorModal] = useState(false);
  // Modal Survey
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [surveyActionType, setSurveyActionType] = useState<SurveyActionType>(
    SurveyActionType.APPROVE,
  );

  const [searchInput, setSearchInput] = useState("");
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);
  const debouncedSearch = useDebounce(searchInput, DEBOUNCE_TIME);

  const { t } = useTranslation("translations", {
    keyPrefix: "pages.approvals.transactionsTable",
  });

  function userAnsweredSatisfactionSurvey(
    actionType: SurveyActionType,
  ): boolean {
    const storageKey =
      actionType === SurveyActionType.APPROVE
        ? `approveSurveyAnswered${ExpenseType.CORPORATE_CARD}`
        : actionType === SurveyActionType.REPROVE
          ? `reproveSurveyAnswered${ExpenseType.CORPORATE_CARD}`
          : `requestReviewSurveyAnswered${ExpenseType.CORPORATE_CARD}`;

    const surveyAnswered = localStorage.getItem(storageKey);
    return surveyAnswered ? JSON.parse(surveyAnswered) : false;
  }

  const {
    isApproveModalOpen,
    setIsApproveModalOpen,
    isRequestReviewModalOpen,
    selectedExpense,
    setIsRequestReviewModalOpen,
    isReproveModalOpen,
    setIsReproveModalOpen,
  } = useTransactionsContext();
  const { table, totalCount, isLoading } = useTransactionTableSetup();
  const { refetch } = useTransactionsTableData();

  const { methods } = useTransactionsContext();
  const { setValue: setValueFilter, watch: watchFilter } = methods;

  function isMultipleSelected(): boolean {
    return (
      Array.isArray(table.selected.selected) &&
      table.selected.selected?.length > 1
    );
  }
  const { mutate: approveMutation, isLoading: isApproving } =
    trpc.expenseAction.approve.useMutation({
      onSuccess: () => {
        refetch();
        setSurveyActionType(SurveyActionType.APPROVE);
        userAnsweredSatisfactionSurvey(SurveyActionType.APPROVE)
          ? setShowApproveSuccessModal(true)
          : setShowSurveyModal(true);
      },
      onError: () => {
        setShowApproveErrorModal(true);
      },
      onSettled: () => {
        setIsApproveModalOpen(false);
      },
    });

  const handleApproveClicked = () => {
    setJustification("");
    setIsApproveModalOpen(true);

    const selectedIds = [...table.selected.selected]
      .map((expense: { original?: { id?: string } }) => expense.original?.id)
      .filter((id): id is string => !!id);

    const ids = selectedIds.length
      ? selectedIds
      : [selectedExpense?.id].filter((id): id is string => !!id);

    approveMutation({
      ids: ids,
    });
  };

  const { mutate: requestReviewMutation, isLoading: isRequestingReview } =
    trpc.expenseAction.requestReview.useMutation({
      onSuccess: () => {
        refetch();

        setSurveyActionType(SurveyActionType.REQUEST_REVIEW);
        userAnsweredSatisfactionSurvey(SurveyActionType.REQUEST_REVIEW)
          ? setShowRequestReviewSuccessModal(true)
          : setShowSurveyModal(true);
      },
      onError: () => {
        setShowRequestReviewErrorModal(true);
      },
      onSettled: () => {
        setIsRequestReviewModalOpen(false);
      },
    });

  const handleRequestReviewClicked = () => {
    setJustification("");
    setIsRequestReviewModalOpen(true);

    const selectedIds = [...table.selected.selected]
      .map((expense: { original?: { id?: string } }) => expense.original?.id)
      .filter((id): id is string => !!id);

    const ids = selectedIds.length
      ? selectedIds
      : [selectedExpense?.id].filter((id): id is string => !!id);

    requestReviewMutation({
      ids: ids,
      comment: justification,
    });
  };

  const { mutate: reproveMutation, isLoading: isReproving } =
    trpc.expenseAction.reprove.useMutation({
      onSuccess: () => {
        refetch();

        setSurveyActionType(SurveyActionType.REPROVE);
        userAnsweredSatisfactionSurvey(SurveyActionType.REPROVE)
          ? setShowReproveSuccessModal(true)
          : setShowSurveyModal(true);
      },
      onError: () => {
        setShowReproveErrorModal(true);
      },
      onSettled: () => {
        setIsReproveModalOpen(false);
      },
    });

  const handleReproveClicked = () => {
    setJustification("");
    setIsReproveModalOpen(true);

    const selectedIds = [...table.selected.selected]
      .map((expense: { original?: { id?: string } }) => expense.original?.id)
      .filter((id): id is string => !!id);

    const ids = selectedIds.length
      ? selectedIds
      : [selectedExpense?.id].filter((id): id is string => !!id);

    reproveMutation({
      ids: ids,
      comment: reason !== REPROVE_REASONS[5].value ? reason : justification,
    });
  };

  const isDisableActionButton = useMemo(() => {
    const itemsToCheck =
      table.selected.selected.length > 0 ? table.selected.selected : table.rows;

    return itemsToCheck.some(
      (item) => item.original?.status !== ExpenseStatus.PENDING_APPROVAL,
    );
  }, [table.selected.selected, table.rows]);

  function changeTableVision(id: string) {
    if (id === ExpenseStatus.PENDING_APPROVAL) {
      setValueFilter("status", [ExpenseStatus.PENDING_APPROVAL]);
    } else {
      setValueFilter("status", [
        ExpenseStatus.PENDING_APPROVAL,
        ExpenseStatus.PENDING_ACCOUNTING,
        ExpenseStatus.FINISHED,
        ExpenseStatus.REJECTED,
        ExpenseStatus.REQUIRE_CHANGES,
        ExpenseStatus.DRAFT,
      ]);
    }

    Promise.resolve().then(() => {
      refetch();
    });

    table.resetSelected();
  }

  function resolveVision() {
    const currentStatus = watchFilter("status") || [];

    if (
      currentStatus.includes(ExpenseStatus.PENDING_APPROVAL) &&
      currentStatus.length === 1
    ) {
      return ExpenseStatus.PENDING_APPROVAL;
    }
    return "ALL";
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);
  };

  const handleFilterChange = (selectedFilters: ExpenseFiltersFields) => {
    setValueFilter("status", selectedFilters?.expenseStatus || []);
    setValueFilter("categoryNanoIds", selectedFilters?.categoryIds || []);
    setValueFilter(
      "valueRange",
      selectedFilters?.valueRange || { min: undefined, max: undefined },
    );
    setValueFilter("pagination.pageNumber", 1);

    setIsFilterDrawerOpen(false);

    Promise.resolve().then(() => {
      refetch();
    });
  };

  useEffect(() => {
    setValueFilter("employeeName", debouncedSearch.trim());
    refetch();
  }, [setValueFilter, refetch, debouncedSearch]);

  return (
    <Fragment>
      <Table.Root variant="soft">
        <Table.Content>
          <Table.FilterSearch
            labelSearch={t("labelSearch")}
            onSearch={(e) =>
              handleSearch(e as React.ChangeEvent<HTMLInputElement>)
            }
            visions={[
              {
                id: ExpenseStatus.PENDING_APPROVAL,
                label: "Aprovação pendente",
              },
              { id: "ALL", label: "Tudo" },
            ]}
            onChangeVision={changeTableVision}
            vision={resolveVision()}
          >
            <PillButton
              icon="IconFilter"
              label={t("filters")}
              onClick={() => setIsFilterDrawerOpen(true)}
              variant="default"
              size="small"
              type="primary"
            />
          </Table.FilterSearch>

          {isLoading ||
          isApproving ||
          isRequestingReview ||
          isReproving ||
          table.rows.length > 0 ? (
            <>
              <Table.Grid.Root
                loading={
                  isLoading || isApproving || isRequestingReview || isReproving
                }
              >
                <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
                {table?.rows.length === 0 ? (
                  <></>
                ) : (
                  table.rows.map((row, index) => (
                    <Table.Grid.Row key={index + row.id} row={row} />
                  ))
                )}
              </Table.Grid.Root>
              {/* Table Actions */}
              <Table.BulkActions
                open={
                  table.selected.allSelected ||
                  table.selected.selected.length > 0
                }
                total={totalCount}
                totalSelected={
                  table.selected.allSelected
                    ? table.pagination.pageSize
                    : table.selected.selected?.length
                }
              >
                <PillButton
                  label={t("columns.actionButtons.approve")}
                  icon="IconCheck"
                  onClick={() => {
                    setIsApproveModalOpen(true);
                  }}
                  variant="success"
                  type="primary"
                  size="small"
                  disabled={isDisableActionButton}
                />

                <PillButton
                  label={t("columns.actionButtons.requestReview")}
                  icon="IconAlertTriangle"
                  onClick={() => setIsRequestReviewModalOpen(true)}
                  variant="default"
                  type="secondary"
                  size="small"
                  disabled={isDisableActionButton}
                />
                <PillButton
                  label={t("columns.actionButtons.reprove")}
                  icon="IconX"
                  onClick={() => setIsReproveModalOpen(true)}
                  variant="default"
                  type="secondary"
                  size="small"
                  disabled={isDisableActionButton}
                />
              </Table.BulkActions>
              <Table.Pagination
                count={totalCount}
                onPaginationChange={(pagination) => {
                  setValueFilter("pagination", pagination);
                }}
                pagination={watchFilter("pagination")}
                pageSizeOptions={pageSizes.map((value) => ({
                  label: value?.toString(),
                  value,
                }))}
              />
            </>
          ) : (
            <>
              {searchInput.length > 0 ? (
                <EmptyList
                  title={t("emptySearchedList.title")}
                  description={t("emptySearchedList.description")}
                  iconName="IconZoomCancel"
                />
              ) : (
                <EmptyList
                  title={t("emptyList.title")}
                  description={t("emptyList.description")}
                  imageUrl="https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/expense-lifecycle/approvals-empty-state.png"
                />
              )}
            </>
          )}
        </Table.Content>
      </Table.Root>

      {/* Success Modal Approve */}
      <SuccessActionModal
        iconPosition={Alignment.left}
        isLoading={false}
        headerIconLabel={t(`modals.approve.headerIconLabel`)}
        isBatchApproval={isMultipleSelected()}
        headerTitle={
          isMultipleSelected()
            ? t(`modals.approve.title.approveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.approve.title.approveSingle`)
        }
        headerSubtitle={
          isMultipleSelected()
            ? t(`modals.approve.subtitle.approveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.approve.subtitle.approveSingle`)
        }
        labelButtonAction={t(`modals.approve.labelButtonAction`)}
        onCloseClick={() => setIsApproveModalOpen(false)}
        open={isApproveModalOpen}
        onActionClick={() => handleApproveClicked()}
      >
        <ModalCard expense={selectedExpense} />
      </SuccessActionModal>
      {/* Success Modal Approve Confirmation */}
      <SuccessActionModal
        iconPosition={Alignment.left}
        isLoading={false}
        showActionButton={false}
        showCancelButton={false}
        showCloseButton={true}
        headerIconLabel={t(`modals.approveSuccess.headerIconLabel`)}
        headerTitle={
          isMultipleSelected()
            ? t(`modals.approveSuccess.title.approveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.approveSuccess.title.approveSingle`)
        }
        headerSubtitle={
          isMultipleSelected()
            ? t(`modals.approveSuccess.subtitle.approveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.approveSuccess.subtitle.approveSingle`)
        }
        onCloseClick={() => {
          setShowApproveSuccessModal(false);
          table.resetSelected();
        }}
        open={showApproveSuccessModal}
      />
      {/* Danger Modal Approve Error */}
      <DangerActionModal
        iconPosition={Alignment.left}
        isLoading={isLoading}
        showActionButton={false}
        showCancelButton={false}
        showCloseButton={true}
        headerIconLabel={t(`modals.approveError.headerIconLabel`)}
        headerTitle={
          isMultipleSelected()
            ? t(`modals.approveError.title.approveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.approveError.title.approveSingle`)
        }
        headerSubtitle={t(`modals.approveError.subtitle`)}
        onCloseClick={() => {
          setShowApproveErrorModal(false);
          table.resetSelected();
        }}
        onCancelClick={() => {
          setShowApproveErrorModal(false);
          table.resetSelected();
        }}
        open={showApproveErrorModal}
      />
      {/* Danger Modal Request Review */}
      <DangerActionModal
        iconPosition={Alignment.left}
        isLoading={isLoading}
        showActionButton={true}
        showCancelButton={true}
        headerIconLabel={t(`modals.requestReview.headerIconLabel`)}
        headerTitle={
          isMultipleSelected()
            ? t(`modals.requestReview.title.approveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.requestReview.title.approveSingle`)
        }
        headerSubtitle={
          isMultipleSelected()
            ? t(`modals.requestReview.subtitle.approveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.requestReview.subtitle.approveSingle`)
        }
        onCloseClick={() => setIsRequestReviewModalOpen(false)}
        onCancelClick={() => setIsRequestReviewModalOpen(false)}
        open={isRequestReviewModalOpen}
        onActionClick={() => handleRequestReviewClicked()}
        labelButtonAction={t(`modals.requestReview.labelButtonAction`)}
        isActionDisabled={
          justification.length < MIN_CHAR_REQUEST_REVIEW_COMMENT
        }
      >
        <ModalItemsContainer>
          <ModalCard expense={selectedExpense} />
        </ModalItemsContainer>
        <TextArea
          width="100%"
          id="justification"
          aria-pressed
          placeholder={t(`modals.requestReview.placeholder`)}
          resizable
          maxLength={MAX_CHAR_REQUEST_REVIEW_COMMENT}
          onChange={(event) =>
            setJustification((event.target as HTMLTextAreaElement)?.value)
          }
          value={justification ?? ""}
        />
      </DangerActionModal>
      {/* Success Modal Request Review Confirmation */}
      <SuccessActionModal
        iconPosition={Alignment.left}
        isLoading={false}
        showActionButton={false}
        showCancelButton={false}
        showCloseButton={true}
        headerIconLabel={t(`modals.requestReviewSuccess.headerIconLabel`)}
        headerTitle={
          isMultipleSelected()
            ? t(`modals.requestReviewSuccess.title.approveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.requestReviewSuccess.title.approveSingle`)
        }
        onCloseClick={() => {
          setShowRequestReviewSuccessModal(false);
          table.resetSelected();
        }}
        open={showRequestReviewSuccessModal}
      />
      {/* Danger Modal Request Review Error */}
      <DangerActionModal
        iconPosition={Alignment.left}
        isLoading={isLoading}
        showActionButton={false}
        showCancelButton={false}
        showCloseButton={true}
        headerIconLabel={t(`modals.requestReviewError.headerIconLabel`)}
        headerTitle={
          isMultipleSelected()
            ? t(`modals.requestReviewError.title.approveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.requestReviewError.title.approveSingle`)
        }
        headerSubtitle={t(`modals.requestReviewError.subtitle`)}
        onCloseClick={() => {
          setShowRequestReviewErrorModal(false);
          table.resetSelected();
        }}
        onCancelClick={() => {
          setShowRequestReviewErrorModal(false);
          table.resetSelected();
        }}
        open={showRequestReviewErrorModal}
      />
      {/* Danger Modal Request Review Error */}
      <ErrorActionModal
        iconPosition={Alignment.left}
        isLoading={false}
        showActionButton={true}
        showCancelButton={true}
        labelButtonAction={t(`modals.reprove.labelButtonAction`)}
        headerIconLabel={t(`modals.reprove.headerIconLabel`)}
        isActionDisabled={reason === ""}
        headerTitle={
          isMultipleSelected()
            ? t(`modals.reprove.title.approveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.reprove.title.approveSingle`)
        }
        headerSubtitle={
          isMultipleSelected()
            ? t(`modals.reprove.subtitle.approveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.reprove.subtitle.approveSingle`)
        }
        onCloseClick={() => {
          setIsReproveModalOpen(false);
          table.resetSelected();
        }}
        onCancelClick={() => {
          setIsReproveModalOpen(false);
          table.resetSelected();
        }}
        open={isReproveModalOpen}
        onActionClick={handleReproveClicked}
      >
        <ModalItemsContainer>
          <ModalCard expense={selectedExpense} />
        </ModalItemsContainer>

        <Flex direction="column" gap="xs5">
          <Typography.Body4 color="neutral.40">
            {t(`modals.reprove.reason`)}
          </Typography.Body4>
        </Flex>
        <ModalItemsContainer>
          <SelectField
            searchable
            id="reason"
            disabled={false}
            onSelectChange={(_, value) =>
              setReason(value?.value ? value.value : "")
            }
            value={reason}
            options={REPROVE_REASONS}
            fullWidth={true}
            label={t(`modals.reprove.labelReason`)}
          />
        </ModalItemsContainer>
        {reason === REPROVE_REASONS[5].value && (
          <TextArea
            width="100%"
            id="justification"
            aria-pressed
            placeholder={t(`modals.reprove.placeholder`)}
            resizable
            maxLength={MAX_CHAR_REQUEST_REVIEW_COMMENT}
            onChange={(event) =>
              setJustification((event.target as HTMLTextAreaElement)?.value)
            }
            value={justification ?? ""}
          />
        )}
      </ErrorActionModal>
      {/* Success Modal Reprove Confirmation */}
      <SuccessActionModal
        iconPosition={Alignment.left}
        isLoading={false}
        showActionButton={false}
        showCancelButton={false}
        showCloseButton={true}
        headerIconLabel={t(`modals.reproveSuccess.headerIconLabel`)}
        headerTitle={
          isMultipleSelected()
            ? t(`modals.reproveSuccess.title.reproveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.reproveSuccess.title.reproveSingle`)
        }
        headerSubtitle={
          isMultipleSelected()
            ? t(`modals.reproveSuccess.subtitle.reproveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.reproveSuccess.subtitle.reproveSingle`)
        }
        onCloseClick={() => {
          setShowReproveSuccessModal(false);
          table.resetSelected();
        }}
        open={showReproveSuccessModal}
      />
      {/* Danger Modal Reprove Error */}
      <DangerActionModal
        iconPosition={Alignment.left}
        isLoading={isLoading}
        showActionButton={false}
        showCancelButton={false}
        showCloseButton={true}
        headerIconLabel={t(`modals.reproveError.headerIconLabel`)}
        headerTitle={
          isMultipleSelected()
            ? t(`modals.reproveError.title.reproveMultiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.reproveError.title.reproveSingle`)
        }
        headerSubtitle={t(`modals.reproveError.subtitle`)}
        onCloseClick={() => {
          setShowReproveErrorModal(false);
          table.resetSelected();
        }}
        onCancelClick={() => {
          setShowReproveErrorModal(false);
          table.resetSelected();
        }}
        open={showReproveErrorModal}
      />

      {/* Survey Success Action Modal */}
      <SuccessActionModal
        iconPosition={Alignment.left}
        isLoading={false}
        showActionButton={false}
        showCancelButton={false}
        showCloseButton={true}
        headerIconLabel={t(`modals.survey.headerIconLabel`)}
        headerTitle={
          isMultipleSelected()
            ? t(`modals.survey.title.${surveyActionType}Multiple`, {
                count: table.selected.selected?.length ?? 1,
              })
            : t(`modals.survey.title.${surveyActionType}Single`)
        }
        headerSubtitle={
          isMultipleSelected()
            ? t(`modals.survey.subtitle.${surveyActionType}Multiple`, {
                count: table.selected.selected?.length ?? 1,
              } as { count: number })
            : t(`modals.survey.subtitle.${surveyActionType}Single`)
        }
        onCloseClick={() => {
          setShowSurveyModal(false);
          table.resetSelected();
        }}
        open={showSurveyModal}
      >
        <ApprovalsSurvey
          title={t(`modals.survey.body.${surveyActionType}Title`)}
          actionType={surveyActionType}
          expenseType={ExpenseType.CORPORATE_CARD}
        />
      </SuccessActionModal>

      {/* Filter Drawer */}
      <ExpenseFilterDrawer
        expenseType={ExpenseType.CORPORATE_CARD}
        isOpen={isFilterDrawerOpen}
        onFilterCountChange={(count) => setSelectedFiltersCount(count)}
        onClose={() => setIsFilterDrawerOpen(false)}
        onSubmit={(selectedFilters: ExpenseFiltersFields) => {
          handleFilterChange(selectedFilters);
        }}
        filters={{
          expenseStatus: watchFilter("status"),
          categoryIds: watchFilter("categoryNanoIds"),
          valueRange: watchFilter("valueRange"),
        }}
        config={{
          expenseStatus: true,
          categoryIds: true,
          valueRange: true,
        }}
      />
    </Fragment>
  );
}
