import {
  ExpenseStatus,
  TransactionStatus,
} from "$services/expense/types/expense/expense.types";

export const common = {
  expenseStatus: {
    REIMBURSEMENT: {
      [ExpenseStatus.DRAFT]: "Pendente",
      [ExpenseStatus.REQUIRE_CHANGES]: "Em revisão",
      [ExpenseStatus.PENDING_APPROVAL]: "Em aprovação",
      [ExpenseStatus.PENDING_ACCOUNTING]: "Em pagamento",
      [ExpenseStatus.FINISHED]: "Reembolsado",
      [ExpenseStatus.REJECTED]: "Reprovado",
    },
    CORPORATE_CARD: {
      [ExpenseStatus.DRAFT]: "Pendente",
      [ExpenseStatus.REQUIRE_CHANGES]: "Em revisão",
      [ExpenseStatus.PENDING_APPROVAL]: "Em aprovação",
      [ExpenseStatus.PENDING_ACCOUNTING]: "Em fechamento",
      [ExpenseStatus.FINISHED]: "Finalizada",
      [ExpenseStatus.REJECTED]: "Reprovada",
    },
    TRANSACTION: {
      [TransactionStatus.AUTHORIZED]: "Autorizada",
      [TransactionStatus.COMPLETED]: "Confirmada",
      [TransactionStatus.SETTLED]: "Autorizada",
      [TransactionStatus.CANCELED]: "Cancelada",
      [TransactionStatus.REVERTED]: "Revertida",
      [TransactionStatus.PROCESSING]: "Processando",
    },
    POLICY_STATUS: {
      INSIDE_POLITICS: "Dentro da política",
      OUTSIDE_POLITICS: "Fora da política",
    },
  },
};

export default {
  translations: {
    pages: {
      approvals: {
        title: "Aprovações",
        breadcrumbItems: {
          home: "Início",
          approvals: "Aprovações",
        },
        tabs: {
          cardExpenses: "Despesas de cartão",
          reimbursements: "Reembolsos",
          balanceRequests: "Pedidos de saldo",
        },
        reimbursementsTable: {
          labelSearch: "Buscar por membro da equipe",
          filters: "Filtros",
          vision: {
            pendingApproval: "Aprovação pendente",
            all: "Tudo",
          },
          noCategory: "Sem categoria",
          columns: {
            date: "Data",
            employee: "Membro da equipe",
            description: "Descrição",
            amount: "Valor",
            receipt: "Recibos",
            status: "Status",
            policy: "Política",
            costCenter: "Centro de Custo",
            comment: "Comentário",
            actions: "Ações",
            actionButtons: {
              approve: "Aprovar",
              requestReview: "Solicitar revisão",
              seeDetails: "Ver detalhes do reembolso",
              reprove: "Reprovar prestação",
            },
          },
          expenseReceipts: {
            attachmentsCount: "Despesa sem recibos vinculados.",
            loadingReceipts: "Carregando recibos...",
            noReceipts: "Sem Recibo",
            hasReceipts: "Ver Recibo",
          },
          imagesModal: {
            toasts: {
              failedLoadingReceipts: {
                title: "Erro!",
                description:
                  "Falha ao carregar recibos, tente novamente mais tarde",
              },
            },
          },
          emptyList: {
            title: "Nenhum aprovação pendente.",
            description:
              "Você poderá revisar aqui as aprovações de despesas de cartão corporativo que precisam da sua aprovação.",
          },
          emptySearchedList: {
            title: "Nenhuma aprovação encontrada.",
            description:
              "Revise a busca, o período selecionado ou os filtros e tente novamente.",
          },
          modals: {
            approve: {
              headerIconLabel: "Atenção!",
              title: {
                approveSingle: "Tem certeza que deseja aprovar o reembolso?",
                approveMultiple:
                  "Tem certeza que deseja aprovar os {{count}} reembolsos?",
              },
              subtitle: {
                approveSingle:
                  "Após a sua aprovação, o reembolso seguirá para revisão do financeiro.",
                approveMultiple:
                  "Após a sua aprovação, os reembolsos seguirão para revisão do financeiro",
              },
              labelButtonAction: "Aprovar",
            },
            approveSuccess: {
              headerIconLabel: "Tudo certo!",
              title: {
                approveSingle: "Reembolso aprovado com sucesso!",
                approveMultiple: "{{count}} reembolsos aprovados com sucesso!",
              },
              subtitle: {
                approveSingle:
                  "O reembolso seguirá para aprovação do financeiro.",
                approveMultiple:
                  "Os reembolsos seguirão para a aprovação do financeiro.",
              },
            },
            approveError: {
              headerIconLabel: "Atenção!",
              title: {
                approveSingle: "Não foi possível aprovar o reembolso.",
                approveMultiple: "Não foi possível aprovar os reembolsos.",
              },
              subtitle:
                "Por favor, tente novamente dentro de alguns instantes.",
            },
            requestReview: {
              title: {
                approveSingle:
                  "Tem certeza que deseja solicitar a revisão do reembolso?",
                approveMultiple:
                  "Tem certeza que deseja solicitar a revisão de {{count}} reembolsos?",
              },
              headerIconLabel: "Atenção!",
              subtitle: {
                approveSingle:
                  "O membro da equipe receberá como uma pendência para revisar o reembolso.",
                approveMultiple:
                  "Os membros da equipe receberão como uma pendência para revisar os reembolsos.",
              },
              labelButtonAction: "Solicitar revisão",
              placeholder: "Adicione uma observação",
            },
            requestReviewSuccess: {
              headerIconLabel: "Tudo certo!",
              title: {
                approveSingle: "Reembolso enviado para revisão com sucesso!",
                approveMultiple:
                  "{{count}} reembolsos enviados para revisão com sucesso!",
              },
            },
            requestReviewError: {
              headerIconLabel: "Atenção!",
              title: {
                approveSingle:
                  "Não foi possível solicitar revisão do reembolso.",
                approveMultiple:
                  "Não foi possível solicitar revisão dos reembolsos.",
              },
              subtitle:
                "Por favor, tente novamente dentro de alguns instantes.",
            },
            reprove: {
              title: {
                approveSingle: "Tem certeza que deseja reprovar o reembolso?",
                approveMultiple:
                  "Tem certeza que deseja reprovar os {{count}} reembolsos?",
              },
              headerIconLabel: "Atenção!",
              subtitle: {
                approveSingle:
                  "Ao reprovar, o membro da equipe não conseguirá mais realizar uma ação.",
                approveMultiple:
                  "Ao reprovar, os membros da equipe não conseguirão mais realizar uma ação.",
              },
              labelButtonAction: "Reprovar",
              placeholder: "Adicione uma observação",
              reason: "Selecione um motivo para a reprovação",
              labelReason: "Motivo",
            },
            reproveSuccess: {
              headerIconLabel: "Tudo certo!",
              title: {
                reproveSingle: "Reembolso reprovado com sucesso!",
                reproveMultiple: "{{count}} reembolsos reprovados com sucesso!",
              },
              subtitle: {
                reproveSingle:
                  "O membro da equipe será notificado da reprovação.",
                reproveMultiple:
                  "Os membros da equipe serão notificados das reprovações.",
              },
            },
            reproveError: {
              headerIconLabel: "Atenção!",
              title: {
                reproveSingle: "Não foi possível reprovar o reembolso.",
                reproveMultiple: "Não foi possível reprovar os reembolsos.",
              },
              subtitle:
                "Por favor, tente novamente dentro de alguns instantes.",
            },
            survey: {
              headerIconLabel: "Tudo certo!",
              title: {
                approveSingle: "Reembolso aprovado com sucesso!",
                approveMultiple: "{{count}} reembolsos aprovados com sucesso!",
                requestReviewSingle:
                  "Reembolso enviado para revisão com sucesso!",
                requestReviewMultiple:
                  "{{count}}} reembolsos enviados para revisão com sucesso!",
                reproveSingle: "Reembolso reprovado com sucesso!",
                reproveMultiple: "{{count}} reembolsos reprovados com sucesso!",
              },
              subtitle: {
                approveSingle:
                  "O reembolso seguirá para aprovação do financeiro.",
                approveMultiple:
                  "Os reembolsos seguirão para a aprovação do financeiro.",
                requestReviewSingle: "",
                requestReviewMultiple: "",
                reproveSingle:
                  "O membro da equipe será notificado da reprovação.",
                reproveMultiple:
                  "Os solicitantes serão notificados da reprovação",
              },
              body: {
                approveTitle:
                  "Como você avalia a facilidade de uso da plataforma ao aprovar um reembolso?",
                requestReviewTitle:
                  "Como você avalia a facilidade de uso da plataforma ao solicitar revisão de um reembolso?",
                reproveTitle:
                  "Como você avalia a facilidade de uso da plataforma ao reprovar um reembolso?",
              },
            },
          },
        },
        transactionsTable: {
          labelSearch: "Buscar por membro da equipe",
          filters: "Filtros",
          noCategory: "Sem categoria",
          columns: {
            date: "Data",
            employee: "Membro da equipe",
            movement: "Movimentação",
            amount: "Valor",
            receipt: "Recibos",
            status: "Status da prestação",
            policy: "Política",
            payment: {
              title: "Pagamento",
              cardLastDigits: "Final",
            },
            costCenter: "Centro de Custo",
            comment: "Comentário",
            actions: "Ações",
            actionButtons: {
              approve: "Aprovar",
              requestReview: "Solicitar revisão",
              seeDetails: "Ver detalhes da prestação",
              reprove: "Reprovar prestação",
            },
          },
          emptyList: {
            title: "Nenhum aprovação pendente.",
            description:
              "Você poderá revisar aqui as aprovações de despesas de cartão corporativo que precisam da sua aprovação.",
          },
          emptySearchedList: {
            title: "Nenhuma aprovação encontrada.",
            description:
              "Revise a busca, o período selecionado ou os filtros e tente novamente.",
          },
          modals: {
            approve: {
              headerIconLabel: "Atenção!",
              title: {
                approveSingle:
                  "Tem certeza que deseja aprovar a prestação de conta?",
                approveMultiple:
                  "Tem certeza que deseja aprovar as {{count}} prestações de conta?",
              },
              subtitle: {
                approveSingle:
                  "Após a sua aprovação, a prestação seguirá para revisão do financeiro.",
                approveMultiple:
                  "Após a sua aprovação, as prestações seguirão para revisão do financeiro",
              },
              labelButtonAction: "Aprovar",
            },
            approveSuccess: {
              headerIconLabel: "Tudo certo!",
              title: {
                approveSingle: "Prestação de conta aprovada com sucesso!",
                approveMultiple:
                  "{{count}} prestações de conta aprovadas com sucesso!",
              },
              subtitle: {
                approveSingle:
                  "A prestação seguirá para aprovação do financeiro.",
                approveMultiple:
                  "As prestações seguirão para a aprovação do financeiro.",
              },
            },
            approveError: {
              headerIconLabel: "Atenção!",
              title: {
                approveSingle: "Não foi possível aprovar a prestação de conta.",
                approveMultiple:
                  "Não foi possível aprovar as prestações de conta.",
              },
              subtitle:
                "Por favor, tente novamente dentro de alguns instantes.",
            },
            requestReview: {
              title: {
                approveSingle:
                  "Tem certeza que deseja solicitar a revisão da prestação de conta?",
                approveMultiple:
                  "Tem certeza que deseja solicitar a revisão de {{count}} prestações de conta?",
              },
              headerIconLabel: "Atenção!",
              subtitle: {
                approveSingle:
                  "O membro da equipe receberá como uma pendência para revisar a prestação de conta.",
                approveMultiple:
                  "Os membros da equipe receberão como uma pendência para revisar as prestações de conta.",
              },
              labelButtonAction: "Solicitar revisão",
              placeholder: "Adicione uma observação",
            },
            requestReviewSuccess: {
              headerIconLabel: "Tudo certo!",
              title: {
                approveSingle:
                  "Prestação de conta enviada para revisão com sucesso!",
                approveMultiple:
                  "{{count}} prestações de conta enviadas para revisão com sucesso!",
              },
            },
            requestReviewError: {
              headerIconLabel: "Atenção!",
              title: {
                approveSingle:
                  "Não foi possível solicitar revisão da prestação de conta.",
                approveMultiple:
                  "Não foi possível solicitar a revisão das prestações de conta.",
              },
              subtitle:
                "Por favor, tente novamente dentro de alguns instantes.",
            },
            reprove: {
              title: {
                approveSingle:
                  "Tem certeza que deseja reprovar a prestação de conta?",
                approveMultiple:
                  "Tem certeza que deseja reprovar as {{count}} prestações de conta?",
              },
              headerIconLabel: "Atenção!",
              subtitle: {
                approveSingle:
                  "Ao reprovar, o membro da equipe não conseguirá mais realizar uma ação.",
                approveMultiple:
                  "Ao reprovar, os membros da equipe não conseguirão mais realizar uma ação.",
              },
              labelButtonAction: "Reprovar",
              placeholder: "Adicione uma observação",
              reason: "Selecione um motivo para a reprovação",
              labelReason: "Motivo",
            },
            reproveSuccess: {
              headerIconLabel: "Tudo certo!",
              title: {
                reproveSingle: "Prestação de conta reprovada com sucesso!",
                reproveMultiple:
                  "{{count}} prestações de conta reprovadas com sucesso!",
              },
              subtitle: {
                reproveSingle:
                  "O membro da equipe será notificado da reprovação.",
                reproveMultiple:
                  "Os membros da equipe serão notificados das reprovações.",
              },
            },
            reproveError: {
              headerIconLabel: "Atenção!",
              title: {
                reproveSingle:
                  "Não foi possível reprovar a prestação de conta.",
                reproveMultiple:
                  "Não foi possível reprovar as prestações de conta.",
              },
              subtitle:
                "Por favor, tente novamente dentro de alguns instantes.",
            },
            survey: {
              headerIconLabel: "Tudo certo!",
              title: {
                approveSingle: "Prestação de contas aprovada com sucesso!",
                approveMultiple:
                  "{{count}} Prestações de contas aprovadas com sucesso!",
                requestReviewSingle:
                  "Prestação de contas enviada para revisão com sucesso!",
                requestReviewMultiple:
                  "{{count}} prestações de conta enviadas para revisão com sucesso!",
                reproveSingle: "Prestação de contas reprovada com sucesso!",
                reproveMultiple:
                  "{{count}} Prestações de contas reprovadas com sucesso!",
              },
              subtitle: {
                approveSingle:
                  "A prestação seguirá para aprovação do financeiro.",
                approveMultiple:
                  "As prestações seguirão para aprovação do financeiro.",
                requestReviewSingle: "",
                requestReviewMultiple: "",
                reproveSingle:
                  "O membro da equipe será notificado da reprovação",
                reproveMultiple:
                  "Os membros da equipe serão notificados da reprovação.",
              },
              body: {
                approveTitle:
                  "Como você avalia a facilidade de uso da plataforma ao aprovar uma prestação de contas?",
                requestReviewTitle:
                  "Como você avalia a facilidade de uso da plataforma ao solicitar revisão de uma prestação de contas?",
                reproveTitle:
                  "Como você avalia a facilidade de uso da plataforma ao reprovar uma prestação de contas?",
              },
            },
          },
        },
        expenseFilterDrawer: {
          filters: "Filtros",
          submit: "Filtrar",
          reset: "Limpar filtros",
          noCategory: "Sem categoria",
          sliderField: {
            min: "Valor Mínimo",
            max: "Valor Máximo",
          },
          amountRange: {
            title: "Valor",
          },
          fields: {
            dateRange: {
              title: "Período",
              presets: {
                lastMonth_one: "Mês passado",
                lastMonth_other: "Últimos {{count}} meses",
              },
            },
            corporateCardStatus: {
              title: "Status da despesa",
              labels: common.expenseStatus.CORPORATE_CARD,
            },
            reimbursementsStatus: {
              title: "Status do reembolso",
              labels: common.expenseStatus.REIMBURSEMENT,
            },
            transactionStatus: {
              title: "Status da movimentação",
              labels: common.expenseStatus.TRANSACTION,
            },
            category: {
              title: "Categoria",
            },
          },
        },
      },
    },
    components: {
      actionModal: {
        cancel: "Cancelar",
        confirm: "Confirmar",
        closeButton: "Fechar",
      },
      datePeriodMenu: {
        lastNinety: "Últimos 90 dias",
        lastThirty: "Últimos 30 dias",
        lastFifteen: "Últimos 15 dias",
        lastSeven: "Últimos 7 dias",
        lastMonth: "Este mês",
        custom: "Personalizado",
      },
      dateRangeModal: {
        title: "Defina um período personalizado",
        buttons: {
          cancel: "Cancelar",
          apply: "Aplicar",
        },
        helpMessage:
          "Como os valores estão em constante movimentação, ao selecionar a data de hoje os resultados podem sofrer variações.",
        warningMessage:
          "Você pode selecionar um intervalo de, no máximo, 60 dias corridos.",
      },
      checkboxGroup: {
        selectAll: "Selecionar tudo",
      },
      survey: {
        scores: {
          terrible: "Péssima",
          bad: "Ruim",
          regular: "Regular",
          good: "Boa",
          great: "Ótima",
        },
      },
    },
  },
};
