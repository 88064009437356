import {
  IDispatchLogoutParams,
  IDispatchToastParams,
} from "./web-events.utils.types";

export function dispatchLogout(detail?: IDispatchLogoutParams) {
  dispatchEvent(new CustomEvent("logout", { detail }));
}

export function dispatchToast(detail: IDispatchToastParams) {
  dispatchEvent(new CustomEvent("showToast", { detail }));
}
