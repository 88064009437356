import {
  Button,
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import { useTranslation } from "react-i18next";

import { Alignment } from "$utils/enum.utils";
import { Flex } from "../Flex";
import {
  IconActionButtonContainer,
  IconClose,
  IconLabel,
  IconLabelContainer,
  ModalBodyContainer,
  ModalContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  StyledActionModal,
  Subtitle,
  Title,
} from "./styled";

export interface ActionModalProps {
  /* if 'true' open the modal */
  open: boolean;
  /* if 'true' the button shows loading status */
  isLoading?: boolean;
  /* Modal content */
  children?: React.ReactNode;
  /* Icon Label header */
  headerIconLabel?: string;
  /* Title header */
  headerTitle?: string;
  /* Subtitle header */
  headerSubtitle?: string;
  /* Text or custom item in header */
  headerCustom?: React.ReactNode;
  /* Text or custom item in footer */
  footerCustom?: React.ReactNode;
  /* Confirmation button custom text or item */
  labelButtonAction?: React.ReactNode;
  /* Icon Action Typography */
  iconActionButton?: React.ReactNode;
  /* Cancel button custom text or item */
  labelButtonCancel?: React.ReactNode;
  /* if 'true' disable button action */
  isActionDisabled?: boolean;
  /* Header icon position */
  iconPosition?: Alignment;
  /* Show Cancel Typography */
  showCancelButton?: boolean;
  /* Custom header icon component */
  headerIcon?: React.ReactNode;
  /* Cancel event */
  onCancelClick?(): void;
  /* Close event */
  onCloseClick?(): void;
  /* Action event */
  onActionClick?(): void;
  /* show action button */
  showActionButton?: boolean;
  /* show close button */
  showCloseButton?: boolean;
}

export const ActionModal = ({
  open,
  isLoading,
  children,
  headerIconLabel,
  headerTitle,
  headerSubtitle,
  headerCustom,
  footerCustom,
  labelButtonAction,
  iconActionButton,
  labelButtonCancel,
  isActionDisabled,
  iconPosition = Alignment.center,
  showCancelButton = true,
  onCancelClick,
  onCloseClick,
  onActionClick,
  showActionButton = true,
  showCloseButton = false,
}: ActionModalProps) => {
  const { t } = useTranslation("translations", {
    keyPrefix: "components.actionModal",
  });

  return (
    <StyledActionModal open={open} onClose={onCloseClick}>
      <ModalContainer>
        <ModalHeaderContainer iconPosition={iconPosition}>
          {Boolean(headerCustom) ? (
            headerCustom
          ) : (
            <>
              <IconClose
                variant="line"
                size="medium"
                onClick={onCloseClick || onCancelClick}
              />

              <IconLabelContainer>
                {Boolean(headerIconLabel) && (
                  <IconLabel variant="body4">
                    <Flex align="center" gap={"xs5"}>
                      <Icons name="IconAlertCircle" size={16} />
                      <Typography variant="body4" weight={700}>
                        {headerIconLabel}
                      </Typography>
                    </Flex>
                  </IconLabel>
                )}
              </IconLabelContainer>

              {Boolean(headerTitle) && (
                <Title variant="headline8">{headerTitle}</Title>
              )}
              {Boolean(headerSubtitle) && (
                <Subtitle variant="body4">{headerSubtitle}</Subtitle>
              )}
            </>
          )}
        </ModalHeaderContainer>
        {Boolean(children) && (
          <ModalBodyContainer>{children}</ModalBodyContainer>
        )}
        <ModalFooterContainer showCancelButton={showCancelButton}>
          {Boolean(footerCustom) ? (
            footerCustom
          ) : (
            <>
              {showCancelButton && (
                <div>
                  <LinkButton
                    variant="neutral"
                    onClick={onCancelClick || onCloseClick}
                  >
                    {labelButtonCancel || t("cancel")}
                  </LinkButton>
                </div>
              )}
              <div>
                {showActionButton && (
                  <Button
                    loading={isLoading}
                    disabled={isActionDisabled}
                    onClick={onActionClick}
                    size="medium"
                    variant="primary"
                  >
                    {labelButtonAction || t("confirm")}
                    {Boolean(iconActionButton) && (
                      <IconActionButtonContainer>
                        {iconActionButton}
                      </IconActionButtonContainer>
                    )}
                  </Button>
                )}
              </div>
              <>
                {showCloseButton && (
                  <Button
                    onClick={onCancelClick || onCloseClick}
                    size="large"
                    variant="secondary"
                    variantType="neutral"
                  >
                    {t("closeButton")}
                  </Button>
                )}
              </>
            </>
          )}
        </ModalFooterContainer>
      </ModalContainer>
    </StyledActionModal>
  );
};
