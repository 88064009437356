import { FC, ReactNode } from "react";

/**
 * Combines multiple Providers into a single component.
 * @param components List of components of type Provider.
 * @returns A single Provider component that wraps all provided components.
 */
export const combineComponents = (
  ...components: FC<{ children: ReactNode }>[]
): FC<{ children: ReactNode }> => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      const Combined: FC<{ children: ReactNode }> = ({ children }) => (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      );
      return Combined;
    },
    ({ children }) => <>{children}</>,
  );
};
