export enum SurveyActionType {
  APPROVE = "approve",
  REPROVE = "reprove",
  REQUEST_REVIEW = "requestReview",
}

export const SURVEY_STORAGE_KEYS = {
  [SurveyActionType.APPROVE]: "approveSurveyAnswered",
  [SurveyActionType.REPROVE]: "reproveSurveyAnswered",
  [SurveyActionType.REQUEST_REVIEW]: "requestReviewSurveyAnswered",
} as const;
