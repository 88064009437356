/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from "@flash-tecnologia/hros-web-ui-v2";

import { Carousel } from "$components/Expense/ExpenseReceipts/FileReader/Carousel";
import { PdfReader } from "$components/Expense/ExpenseReceipts/FileReader/PdfReader";
import { Flex } from "$components/shared/Flex/Flex";
import { Attachment } from "$services/expense/types/expense/expense.types";
import {
  DescriptionLoading,
  Image,
  ImageContainer,
  LoaderContainer,
  LoaderContent,
  ModalBackgroundMask,
  ModalButtonClose,
  ModalContent,
} from "./styled";
import { useInitImagesModal } from "./useInitImagesModal";

export type ImagesModalProps = {
  /** Attachments to load if receiptsSrc is not provided */
  attachments?: Attachment[];
  /** Preloaded receipt sources */
  receiptsSrc?: string[];
  /** Whether to show dots for carousel navigation */
  showDots?: boolean;
  /** Loading description text */
  loadingDescription?: string;
  /** Callback for closing the modal */
  onCloseModalClick: () => void;
  /** Initial index for the carousel */
  initialIndex?: number;
};

export function ImagesModal({
  attachments = [],
  loadingDescription,
  showDots = false,
  onCloseModalClick,
  initialIndex,
  receiptsSrc: propsReceiptsData,
}: ImagesModalProps) {
  const isPdf = (fileName: string) => fileName.endsWith(".pdf");
  const { loading, receiptsSrc } = useInitImagesModal(
    attachments,
    propsReceiptsData,
    onCloseModalClick,
  );

  return (
    <Flex width="100%" align="center" direction="column">
      <ModalBackgroundMask />
      <ModalContent>
        <ModalButtonClose
          key="close-button-modal-images"
          onClick={onCloseModalClick}
          icon="IconX"
          variant="filled"
          size="small"
        />
        {loading ? (
          <LoaderContainer>
            <LoaderContent>
              <Spinner size={48} variant="primary" />
              {loadingDescription && (
                <DescriptionLoading variant="body3">
                  {loadingDescription}
                </DescriptionLoading>
              )}
            </LoaderContent>
          </LoaderContainer>
        ) : (
          <Carousel
            items={receiptsSrc.map(({ fileName, url }, index) => (
              <ImageContainer key={`image-receipt-${index}`}>
                {isPdf(fileName) ? (
                  <PdfReader filename={url} />
                ) : (
                  <Image src={url} />
                )}
              </ImageContainer>
            ))}
            showDots={showDots}
            initialIndex={initialIndex}
          />
        )}
      </ModalContent>
    </Flex>
  );
}
