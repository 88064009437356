import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { ComponentProps } from "react";

import { common } from "$frontend/src/i18n/locales/pt-br";
import { PolicyStatusType } from "$services/expense/types/expense/expense.types";

export type PolicyStatusTagProps = {
  policyStatus?: PolicyStatusType;
};

const politicsStatusColors = {
  [PolicyStatusType.INSIDE_POLITICS]: "green",
  [PolicyStatusType.OUTSIDE_POLITICS]: "red",
} satisfies Record<PolicyStatusType, ComponentProps<typeof Tag>["variant"]>;

export function PolicyStatusTag({ policyStatus }: PolicyStatusTagProps) {
  if (!policyStatus) return <></>;

  const getVariant = () => {
    if (policyStatus) {
      return politicsStatusColors[policyStatus];
    }
    return "gray";
  };

  return (
    <Tag as="div" hasLeftDotIcon variant={getVariant()} size="small">
      {common.expenseStatus.POLICY_STATUS[policyStatus]}
    </Tag>
  );
}
