import dayjs from "dayjs";

export function formatDate(
  dateString: string,
  format: "BR" | "US" = "BR",
): string {
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();

  return format === "BR"
    ? `${day}/${month}/${year}`
    : `${month}/${day}/${year}`;
}

/**
 * Format Date to DD/MM/YYYY
 *
 * Ex: 22/08/2016
 **/
export function formatDateToString(date: Date) {
  return dayjs(date).format("DD/MM/YYYY");
}

/**
 * Format Date period
 *
 * Ex: DD/MM/YYYY - DD/MM/YYYY
 **/
export function formatDatePeriodToString(firstDate: Date, lastDate: Date) {
  return `${formatDateToString(firstDate)} - ${formatDateToString(lastDate)}`;
}

export function getLastMinuteAndSecondOfDay(date: Date): Date {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
}
