import {
  ColorToken,
  Dot,
  IconTypes,
  ShapeIcon,
  ShapeIconOptions,
  Tooltip,
  Typography,
  VariantsType,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";

import { Container, SubTitleContainer, TextContainer, TextRow } from "./styled";
import { TagsType } from "./tags.types";

type InfoCardProps = {
  /* Name of the icon to be displayed */
  iconName?: IconTypes | string;
  /* Title of the card */
  title: string;
  /* Subtitle of the card */
  subtitle?: string;
  /* Description of the card */
  description?: string;
  /* General Settings */
  settings?: {
    /* Icon Settings */
    icon?: {
      variant?: ShapeIconOptions;
      color?: ColorToken;
      size?: number;
      strokeWidth?: string;
      stroke?: ShapeIconOptions;
    };
    /* Title Settings */
    title?: {
      variant?: VariantsType;
      as?: TagsType;
      color?: ColorToken;
      weight?: 400 | 500 | 600 | 700 | 800 | undefined;
    };
    /* Subtitle Settings */
    subtitle?: {
      variant?: VariantsType;
      as?: TagsType;
      color?: ColorToken;
      weight?: 400 | 500 | 600 | 700 | 800 | undefined;
    };
    /* Description Settings */
    description?: {
      variant?: VariantsType;
      as?: TagsType;
      color?: ColorToken;
      weight?: 400 | 500 | 600 | 700 | 800 | undefined;
    };
    /* Dot Settings */
    dot?: {
      variant:
        | "primary"
        | "secondary"
        | "tertiary"
        | "disabled"
        | "success"
        | "info"
        | "error"
        | "progress"
        | "negative"
        | "pink"
        | "gray"
        | "green"
        | "blue"
        | "yellow"
        | "purple"
        | "red";
    };
  };
  showTooltipTitle?: boolean;
};

export function InfoCard({
  iconName,
  title,
  subtitle,
  description,
  settings,
  showTooltipTitle = false,
}: InfoCardProps) {
  const { colors } = useTheme();

  return (
    <Container>
      {iconName && (
        <ShapeIcon
          name={(iconName as IconTypes) || "IconCategory"}
          size={
            typeof settings?.icon?.size === "number" ? settings.icon.size : 32
          }
          variant={(settings?.icon?.variant as ShapeIconOptions) || "neutral"}
          strokeWidth={settings?.icon?.strokeWidth ?? "2"}
          color={settings?.icon?.color || colors.neutral[40]}
        />
      )}

      <TextContainer>
        {title && (
          <Tooltip arrow title={showTooltipTitle && title} placement="top">
            <div>
              <TextRow
                variant={settings?.title?.variant || "body4"}
                color={settings?.title?.color || "neutral.20"}
                weight={settings?.title?.weight || 600}
                as={settings?.title?.as || "p"}
              >
                {title}
              </TextRow>
            </div>
          </Tooltip>
        )}

        <SubTitleContainer>
          {subtitle && (
            <Typography
              variant={settings?.subtitle?.variant || "caption"}
              color={settings?.subtitle?.color || "neutral.40"}
              weight={settings?.subtitle?.weight || 400}
              as={settings?.subtitle?.as || "p"}
            >
              {subtitle}
            </Typography>
          )}

          {description && (
            <>
              <Dot
                variant={settings?.dot?.variant || "gray"}
                className="description-dot"
              />
              <Typography
                variant={settings?.description?.variant || "caption"}
                weight={settings?.description?.weight || 400}
                color={settings?.description?.color || "neutral.40"}
                as={settings?.description?.as || "p"}
              >
                {description}
              </Typography>
            </>
          )}
        </SubTitleContainer>
      </TextContainer>
    </Container>
  );
}
