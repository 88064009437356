import {
  colors,
  tableControllers,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import { ExpenseReceipts } from "$components/Expense/ExpenseReceipts";
import { ExpenseStatusTag } from "$components/Expense/ExpenseStatusTag";
import { EmptyCell, InfoCard, MenuOption } from "$components/shared/";
import { ButtonGroup, ButtonGroupOption } from "$components/shared/ButtonGroup";
import { getIconNameByCategoryDescription } from "$components/shared/ModalCard/getIconName.utils";
import { Routes } from "$frontend/src/routes/routes";
import {
  CorporateCardExpense,
  ExpenseStatus,
} from "$services/expense/types/expense/expense.types";
import { getValueWithCurrency } from "$utils/currency.utils";
import { formatDate } from "$utils/date.utils";
import { useTransactionsContext } from "../context/TransactionsContext";
import { useTransactionsTableData } from "../data/useTransactionsTableData";

type Table = ReturnType<
  typeof tableControllers.useTableColumns<CorporateCardExpense>
>;

type Columns = Parameters<
  typeof tableControllers.useTableColumns<CorporateCardExpense>
>[number]["columns"];

type ActionOptions = "APPROVE" | "REQUEST_REVIEW" | "VERTICAL_DOTS" | "VIEW";

export function useTransactionTableSetup(): {
  table: Table;
  isLoading: boolean;
  totalCount: number;
  totalPages: number;
} {
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.approvals.transactionsTable",
  });

  const { data, isLoading } = useTransactionsTableData();
  const { methods } = useTransactionsContext();

  const {
    setIsApproveModalOpen,
    setIsRequestReviewModalOpen,
    setSelectedExpense,
    setIsReproveModalOpen,
  } = useTransactionsContext();

  function OPTIONS(
    expense: CorporateCardExpense,
  ): Record<ActionOptions, ButtonGroupOption> {
    const disabled =
      table.selected.selected.length > 0 &&
      !table.selected.selected.some(({ id }) => id == expense.id);

    const handleNavigate = (expense: CorporateCardExpense) => {
      window.open(
        Routes.MOVEMENT_APPROVAL.replace(":expenseId", expense.id),
        "_blank",
      );
    };

    return {
      APPROVE: {
        icon: "IconCheck",
        label: t("columns.actionButtons.approve"),
        onClick: () => {
          setSelectedExpense(expense);
          setIsApproveModalOpen(true);
        },
        iconColor: colors.feedback.success[40],
        disabled,
      },
      REQUEST_REVIEW: {
        icon: "IconAlertTriangle",
        label: t("columns.actionButtons.requestReview"),
        onClick: () => {
          setSelectedExpense(expense);
          setIsRequestReviewModalOpen(true);
        },
        iconColor: colors.feedback.error[40],
        disabled,
      },
      VIEW: {
        icon: "IconFileDescription",
        label: t("columns.actionButtons.seeDetails"),
        onClick: () => handleNavigate(expense),
        iconColor: colors.neutral[40],
        disabled,
      },
      VERTICAL_DOTS: {
        icon: "IconDotsVertical",
        label: "",
        menuOptions: [
          {
            onClick: () => handleNavigate(expense),
            children: (
              <MenuOption
                label={t("columns.actionButtons.seeDetails")}
                icon="IconFileDescription"
              />
            ),
          },
          {
            onClick: () => {
              setSelectedExpense(expense);
              setIsReproveModalOpen(true);
            },
            children: (
              <MenuOption
                icon="IconX"
                iconColor={colors.status.negative[50]}
                label={t(`columns.actionButtons.reprove`)}
                labelColor={"status.negative.50"}
              />
            ),
          },
        ],
        iconColor: colors.neutral[40],
        disabled,
      },
    };
  }

  const getOptions = (
    transaction: CorporateCardExpense,
  ): ButtonGroupOption[] => {
    const action = OPTIONS(transaction);
    switch (transaction.status) {
      case ExpenseStatus.DRAFT:
        return [action.VIEW];
      case ExpenseStatus.REJECTED:
        return [action.VIEW];
      case ExpenseStatus.FINISHED:
        return [action.VIEW];
      case ExpenseStatus.REQUIRE_CHANGES:
        return [action.VIEW];
      case ExpenseStatus.PENDING_APPROVAL:
        return [action.APPROVE, action.REQUEST_REVIEW, action.VERTICAL_DOTS];
      case ExpenseStatus.PENDING_ACCOUNTING:
        return [action.VIEW];
      default:
        return [];
    }
  };

  function renderActions(row: CorporateCardExpense): JSX.Element {
    return <ButtonGroup options={getOptions(row)} />;
  }

  const columns: Columns = [
    {
      header: () => t("columns.date"),
      id: "date",
      minSize: 80,
      cell: ({ row }: { row: { original: CorporateCardExpense } }) => (
        <InfoCard
          title={formatDate(row.original?.date) ?? ""}
          subtitle={new Date(row.original?.date).toLocaleTimeString("pt-BR", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        />
      ),
    },
    {
      header: () => t("columns.employee"),
      id: "employee",
      minSize: 200,
      cell: ({ row }: { row: { original: CorporateCardExpense } }) => (
        <InfoCard
          showTooltipTitle
          title={row.original.employee?.name ?? ""}
          subtitle={row.original.employee?.email ?? ""}
        />
      ),
    },
    {
      header: () => t("columns.movement"),
      id: "movement",
      minSize: 250,
      cell: ({ row }: { row: { original: CorporateCardExpense } }) => (
        <InfoCard
          showTooltipTitle
          title={row.original.transaction?.description ?? ""}
          subtitle={row.original.category?.description ?? ""}
          iconName={getIconNameByCategoryDescription(
            row.original.category?.description,
          )}
        />
      ),
    },
    {
      header: () => t("columns.amount"),
      id: "amount",
      minSize: 120,
      cell: ({ row }: { row: { original: CorporateCardExpense } }) => (
        <Typography variant="body4" color="neutral.20" weight={600}>
          {getValueWithCurrency({
            value: row.original?.amount,
            currencyPrefix: row.original.transaction?.currency,
          })}
        </Typography>
      ),
    },
    {
      header: () => t("columns.receipt"),
      id: "receipt",
      size: 80,
      cell: ({ row }: { row: { original: CorporateCardExpense } }) => (
        <ExpenseReceipts
          key={`receipts-${row.original?.id}`}
          attachments={row.original?.attachments ?? []}
        />
      ),
    },
    ...(() => {
      const currentStatus = methods.getValues("status");
      if (
        currentStatus?.length !== 1 ||
        currentStatus[0] !== ExpenseStatus.PENDING_APPROVAL
      ) {
        return [
          {
            header: () => (
              <Box textAlign="left" whiteSpace="nowrap">
                {t("columns.status")}
              </Box>
            ),
            id: "status",
            cell: ({ row }: { row: { original: CorporateCardExpense } }) => (
              <ExpenseStatusTag status={row.original.status} />
            ),
          },
        ];
      }
      return [];
    })(),
    // TODO: add when policy be done
    // {
    //   header: () => t("columns.policy"),
    //   id: "policy",
    //   size: 120,
    //   cell: ({ row }: { row: { original: CorporateCardExpense } }) => (
    //     <>faltando</>
    //   ),
    // },
    {
      header: () => t("columns.payment.title"),
      id: "payment",
      size: 200,
      cell: ({ row }: { row: { original: CorporateCardExpense } }) => (
        <InfoCard
          iconName="IconCreditCardPay"
          title={row.original.transaction?.cardType ?? ""}
          subtitle={
            row.original?.transaction?.cardLastDigits
              ? `${t("columns.payment.cardLastDigits")} ${row.original?.transaction?.cardLastDigits}`
              : ""
          }
        />
      ),
    },
    {
      header: () => (
        <Box textAlign="left" whiteSpace="nowrap">
          {t("columns.costCenter")}
        </Box>
      ),
      id: "costCenter",
      size: 200,
      cell: ({ row }: { row: { original: CorporateCardExpense } }) => {
        if (!row.original?.costCenter) return <EmptyCell />;
        return <InfoCard title={row.original.costCenter?.name ?? ""} />;
      },
    },
    {
      header: () => t("columns.comment"),
      id: "comment",
      size: 300,
      cell: ({ row }: { row: { original: CorporateCardExpense } }) => {
        if (!row.original?.comments) return <EmptyCell />;
        return (
          <InfoCard showTooltipTitle title={row.original.comments || "-"} />
        );
      },
    },
    {
      header: () => t("columns.actions"),
      size: 60,
      id: "actions",
      sticky: "right",
      cell: ({ row }) => renderActions(row.original),
    },
  ];

  const table = tableControllers.useTableColumns<CorporateCardExpense>({
    total: data?.totalCount ?? 0,
    defaultColumn: {
      minSize: 80,
      maxSize: 300,
    },
    options: {
      selectable: true,
    },
    columns: columns,
    data: data?.expenses ?? [],
    pagination: {
      pageNumber: methods.watch().pagination.pageNumber,
      pageSize: methods.watch().pagination.pageSize,
    },
    onPaginationChange: (pagination) => {
      methods.setValue("pagination", pagination);
    },
  });

  return {
    table,
    isLoading,
    totalCount: data?.totalCount || 0,
    totalPages: data?.totalPages || 0,
  };
}
