import { external } from "@flash-hros/corporate-card";
import { Route } from "react-router-dom";

import RouterService from "$services/RouterService";
import { Routes } from "../../routes/routes";
import PendingApprovals from "./PendingApprovals";
import { ReimbursementsTable } from "./ReimbursementsTable";
import { TransactionsTable } from "./TransactionsTable";

export const ROUTES_PENDING_APPROVALS = RouterService.create(
  Routes.PENDING_APPROVALS,
);

export const ROUTES_PENDING_APPROVALS_REIMBURSEMENT = RouterService.create(
  Routes.REIMBURSEMENTS_APPROVALS,
);

export const ROUTE_PENDING_APPROVALS_TRANSACTIONS = (
  <Route path={ROUTES_PENDING_APPROVALS.path} element={<TransactionTab />} />
);

export const ROUTES_PENDING_APPROVALS_BALANCE_REQUEST = RouterService.create(
  Routes.BALANCE_REQUEST_APPROVAL,
);

export const ROUTE_PENDING_APPROVALS_REIMBURSEMENT = (
  <Route
    path={ROUTES_PENDING_APPROVALS_REIMBURSEMENT.path}
    element={<ReimbursementTab />}
  />
);

export const ROUTE_PENDING_APPROVALS_BALANCE_REQUESTS = (
  <Route
    path={ROUTES_PENDING_APPROVALS_BALANCE_REQUEST.path}
    element={<BalanceTab />}
  />
);

function TransactionTab() {
  return <TransactionsTable />;
}

function ReimbursementTab() {
  return <ReimbursementsTable />;
}

function BalanceTab() {
  const { component } = external.RequestBalanceApprovalTab();

  return <>{component}</>;
}

export const PendingApprovalsPage = (
  <Route
    path={ROUTES_PENDING_APPROVALS.path}
    element={
      <external.ExternalRoot>
        <PendingApprovals />
      </external.ExternalRoot>
    }
  >
    {ROUTE_PENDING_APPROVALS_TRANSACTIONS}
    {ROUTE_PENDING_APPROVALS_REIMBURSEMENT}
    {ROUTE_PENDING_APPROVALS_BALANCE_REQUESTS}
  </Route>
);
