import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs5};
`;

export const Checkbox = styled.div<{ checked: boolean }>`
  border-radius: 4px;

  background-color: ${(props) =>
    props.checked ? props.theme.colors.secondary[99] : undefined};
  color: ${(props) =>
    props.checked ? props.theme.colors.secondary[30] : undefined};
  cursor: pointer;
  transition: all 0.075s ease-in;
  user-select: none;
  font-weight: 600;
`;

export const HorizontalLine = styled.hr`
  border-color: ${(props) => props.theme.colors.neutral[90]};
`;
