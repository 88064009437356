import {
  colors,
  ColorToken,
  ThemesType,
} from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import styled from "styled-components";

type Props = {
  /** align-items */
  align?: "start" | "end" | "center" | "space-around" | "space-between";
  /** flex-direction */
  direction?: "row" | "column";
  /** Gap between children */
  gap?: keyof ThemesType["spacings"];
  /** justify-content */
  justify?: "start" | "end" | "center" | "space-around" | "space-between";
  /** padding */
  padding?: keyof ThemesType["spacings"];
  /** Custom styles */
  style?: React.CSSProperties;
  /** wrap */
  wrap?: "wrap" | "nowrap" | "wrap-reverse";
  /** Children */
  children: React.ReactNode;
  /** Flex-grow */
  grow?: number;
  /** background-color */
  backgroundColor?: ColorToken;
  /** border-width */
  borderWidth?: keyof ThemesType["borders"]["width"];
  /** border-color */
  borderColor?: ColorToken;
  /** border-radius */
  radius?: keyof ThemesType["borders"]["radius"];
  /** height in pixels */
  height?: string;
  /** height in pixels or percentage */
  width?: string;
};

const StyledFlex = styled.div<Props>(({ theme, ...props }) => {
  return {
    width: props.width,
    alignItems: props.align,
    display: "flex",
    flexDirection: props.direction,
    flexWrap: props.wrap,
    gap: props.gap ? theme.spacings[props.gap] : 0,
    justifyContent: props.justify,
    padding: props.padding ? theme.spacings[props.padding] : 0,
    flex: props.grow,
    ...(props.borderWidth && {
      border: `${theme.borders.width[props.borderWidth]} solid ${
        props.borderColor
          ? colors.get(props.borderColor)
          : theme.colors.neutral[90]
      }`,
    }),
    borderRadius: props.radius ? theme.borders.radius[props.radius] : undefined,
    backgroundColor: props.backgroundColor
      ? colors.get(props.backgroundColor)
      : undefined,
    borderColor: props.borderColor ? colors.get(props.borderColor) : undefined,
    height: props.height,
  };
});

export const Flex = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <StyledFlex ref={ref} {...props} />
));

Flex.displayName = "Flex";
