import { SurveyStore } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";

import AuthService from "$services/AuthService";
import { ExpenseType } from "$services/expense/types/expense/expense.types";
import { TrackingService } from "$services/TrackingService";
import { SurveyActionType } from "./survey.types";

type FeedbackData = {
  rating: number;
  acceptedTeamEvaluation: boolean | undefined;
  message: string;
  answers: Record<"ApprovalsEasiness", number>;
};

type SurveyProps = {
  title: string;
  actionType: SurveyActionType;
  expenseType: ExpenseType;
};

const SURVEY_TRACK_EVENTS = {
  [SurveyActionType.APPROVE]: "survey_expense_approvals_approve",
  [SurveyActionType.REPROVE]: "survey_expense_approvals_reprove",
  [SurveyActionType.REQUEST_REVIEW]: "survey_expense_approvals_request_review",
} as const;

export function ApprovalsSurvey({
  title,
  actionType,
  expenseType,
}: SurveyProps) {
  const company = AuthService.useCompany();
  const employeeId = AuthService.useEmployeeId();

  const { t } = useTranslation("translations", {
    keyPrefix: "components.survey.scores",
  });

  const SURVEY_STORAGE_KEYS = {
    [SurveyActionType.APPROVE]:
      expenseType === ExpenseType.REIMBURSEMENT
        ? `approveSurveyAnswered${ExpenseType.REIMBURSEMENT}`
        : `approveSurveyAnswered${ExpenseType.CORPORATE_CARD}`,

    [SurveyActionType.REPROVE]:
      expenseType === ExpenseType.REIMBURSEMENT
        ? `reproveSurveyAnswered${ExpenseType.REIMBURSEMENT}`
        : `reproveSurveyAnswered${ExpenseType.CORPORATE_CARD}`,

    [SurveyActionType.REQUEST_REVIEW]:
      expenseType === ExpenseType.REIMBURSEMENT
        ? `requestReviewSurveyAnswered${ExpenseType.REIMBURSEMENT}`
        : `requestReviewSurveyAnswered${ExpenseType.CORPORATE_CARD}`,
  } as const;

  function handleFeedback(
    data: FeedbackData,
    actionType: SurveyActionType,
    expenseType: ExpenseType,
  ) {
    if (company?.id && employeeId) {
      TrackingService.track(
        SURVEY_TRACK_EVENTS[actionType],
        {
          userId: "",
          experience_feedback: data.message,
          flow_easy_to_use: String(data.answers.ApprovalsEasiness),
          conversation_invitation: String(data.acceptedTeamEvaluation),
          expense_type: expenseType,
          action_type: actionType,
        },
        {
          employeeId,
          companyId: company.id,
        },
      );

      localStorage.setItem(
        SURVEY_STORAGE_KEYS[actionType],
        JSON.stringify(true),
      );

      return true;
    }

    return false;
  }

  return (
    <SurveyStore
      questions={[
        {
          id: "ApprovalsEasiness",
          title: title,
          scores: [
            t("terrible"),
            t("bad"),
            t("regular"),
            t("good"),
            t("great"),
          ],
        },
      ]}
      onSubmit={(data) => handleFeedback(data, actionType, expenseType)}
    />
  );
}
