import dayjs from "dayjs";

import { getLastMinuteAndSecondOfDay } from "$utils/date.utils";
import { Alignment } from "$utils/enum.utils";
import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { ActionModal } from "../ActionModal/ActionModal";
import { DatePeriodState } from "../DatePeriodMenu";
import {
  IconContainer,
  MessageContainer,
  ModalContainer,
  StyledDateRangePicker,
} from "./styled";

type DateRangeModalProps = {
  initialDateRange: DatePeriodState;
  isOpen: boolean;
  onApply(range: DatePeriodState): void;
  onClose(): void;
};

export function DateRangeModal(props: DateRangeModalProps) {
  const { t } = useTranslation("translations", {
    keyPrefix: "components.dateRangeModal",
  });

  const initialRange = useMemo(() => {
    if (props.initialDateRange.selected === "CUSTOM") {
      return {
        from: props.initialDateRange.startDate,
        to: props.initialDateRange.endDate,
      };
    }
    return { from: undefined, to: undefined };
  }, [props.initialDateRange]);

  const [dateRange, setDateRange] = useState<DateRange>(initialRange);
  const [warning, setWarning] = useState(false);

  function onApply(range: DateRange) {
    if (!warning) {
      if (range.from && range.to) {
        props.onApply({
          selected: "CUSTOM",
          startDate: range.from,
          endDate: range.to,
        });
        props.onClose();
      }
    }
  }

  function onDateRangeChange(range?: DateRange) {
    if (range) {
      setDateRange({
        from: range.from,
        to: range.to && getLastMinuteAndSecondOfDay(range.to),
      });
    }
  }
  const isFormValid = useMemo(
    () => !!dateRange.from && !!dateRange.to,
    [dateRange.from, dateRange.to],
  );

  return (
    <ActionModal
      iconPosition={Alignment.left}
      open={props.isOpen}
      onCloseClick={props.onClose}
      headerTitle={t("title")}
      labelButtonAction={t("buttons.apply")}
      onActionClick={() => onApply(dateRange)}
      isActionDisabled={!isFormValid}
    >
      <ModalContainer>
        <StyledDateRangePicker
          initialRange={initialRange}
          disableFooter
          onRangeChange={onDateRangeChange}
          toDate={dayjs().toDate()}
          // required prop, but the button from the modal is being used to submit, instead of the the component's button
          onSubmit={() => null}
        />
        <MessageContainer>
          <IconContainer>
            <ShapeIcon name={"IconInfoCircle"} size={24} variant={"neutral"} />
          </IconContainer>
          <Typography color="neutral.30" variant="caption">
            {t("helpMessage")}
          </Typography>
        </MessageContainer>
      </ModalContainer>
    </ActionModal>
  );
}
