import { Routes } from "$frontend/src/routes/routes";
import {
  Button,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useMemo } from "react";

import {
  ActionContainer,
  Container,
  ContainerIsolatedError,
  IconAlert,
  IsolatedErrorDescription,
  IsolatedErrorModuleName,
  IsolatedErrorTitle,
  Title,
} from "./styled";

interface IErrorBoundaryProps {
  moduleName: string;
  title?: string;
  description?: string;
  printIsolatedError?: boolean;
}

export function ErrorBoundary(props: IErrorBoundaryProps) {
  const createdAt = useMemo(() => new Date().toISOString(), []);

  if (props.printIsolatedError)
    return (
      <ContainerIsolatedError>
        <IconAlert name="IconAlertTriangle" size={40} />
        <div>
          {props.moduleName && (
            <IsolatedErrorModuleName variant="body4">{`Modulo: ${props.moduleName}`}</IsolatedErrorModuleName>
          )}

          {props.title && (
            <IsolatedErrorTitle variant="body3">
              {props.title}
            </IsolatedErrorTitle>
          )}

          {props.description && (
            <IsolatedErrorDescription variant="body4">
              {props.description}
            </IsolatedErrorDescription>
          )}
        </div>
      </ContainerIsolatedError>
    );

  return (
    <Container>
      <div>
        <Title variant="headline4">
          {props.title || "Erro ao tentar carregar a página"}
        </Title>

        <Typography variant="body4">
          <b>Página:</b> <code>{location.href}</code>
        </Typography>

        <Typography variant="body4">
          <b>Módulo:</b> {props.moduleName}
        </Typography>

        <Typography variant="body4">
          <b>Horário:</b> {createdAt}
        </Typography>
      </div>

      <Typography variant="body2">
        Clique em uma das opções abaixo para tentar novamente. <br />
        Caso o erro persistir, entre em contato com o suporte.
      </Typography>

      <ActionContainer>
        <a href={`${Routes.PENDING_APPROVALS}`}>
          <LinkButton variant="default">
            Ir para tela inicial de despesas
          </LinkButton>
        </a>

        <Button
          size="medium"
          variant="primary"
          onClick={() => location.reload()}
        >
          Tentar novamente
        </Button>
      </ActionContainer>
    </Container>
  );
}
