import React from "react";
import { StyledEmptyCell } from "./styled";

interface EmptyCellProps {
  /**
   * Optional character to display instead of "_"
   */
  placeholder?: string;
}

export const EmptyCell: React.FC<EmptyCellProps> = ({ placeholder = "_" }) => {
  return (
    <StyledEmptyCell weight={600} variant="body4">
      {placeholder}
    </StyledEmptyCell>
  );
};
