import {
  TextArea as DSTextArea,
  TextAreaProps as DSTextAreaProps,
} from "@flash-tecnologia/hros-web-ui-v2";

export type ExtendedTextAreaProps = DSTextAreaProps & {
  placeholder?: string;
};

export const TextArea = (props: ExtendedTextAreaProps) => {
  return <DSTextArea {...props} />;
};
