import styled from "styled-components";

export const WarningBadgeContainer = styled.div`
  position: absolute;
  top: -${(props) => props.theme.spacings.xs4};
  right: -${(props) => props.theme.spacings.xs4};
`;

export const Container = styled.div`
  position: relative;
`;
