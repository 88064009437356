import { IconsProps } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Icons";
import { MenuItem } from "./MenuItem";
import * as SC from "./styled";

type MenuProps = {
  children: React.ReactNode;
  options: {
    label: string;
    icon?: IconsProps["name"];
    description?: string;
    selected?: boolean;
    onClick(): void;
  }[];
};

export function Menu(props: MenuProps) {
  return (
    <SC.Container
      type="default"
      options={props.options.map((option) => ({
        children: <MenuItem {...option} />,
        onClick: option.onClick,
        selected: option.selected,
      }))}
    >
      {props.children}
    </SC.Container>
  );
}
