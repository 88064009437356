import { ErrorBoundary } from "$components/shared/ErrorBoundary";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import Root from "./root.component";

const approvals = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    return <ErrorBoundary moduleName="expense-approvals" printIsolatedError />;
  },
});

export const { bootstrap, mount, unmount } = approvals;
