import {
  Checkbox as DSCheckbox,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, Container, HorizontalLine } from "./styled";

type CheckboxGroupProps<ValueType> = {
  options: {
    value: ValueType;
    label: string;
  }[];
  value?: ValueType[];
  onChange: (value: ValueType[] | undefined) => void;
  loading?: boolean;
  key?: string;
};
export const CheckboxGroup = <ValueType,>(
  props: CheckboxGroupProps<ValueType>,
) => {
  const { t } = useTranslation("translations", {
    keyPrefix: "components.checkboxGroup",
  });

  const initialValue = props.value ?? [];
  const initCheckmarks = () =>
    props.options.map((option) => initialValue.includes(option.value));
  const [checkmarks, setCheckmarks] = useState(initCheckmarks());

  const checkmarksCount = checkmarks.reduce<number>(
    (count, check) => (check ? count + 1 : count),
    0,
  );

  useEffect(() => {
    const value = props.options
      .filter((_, index) => checkmarks[index])
      .map((option) => option.value);
    props.onChange(value.length == 0 ? undefined : value);
  }, [checkmarks]);

  const loading = props.loading ?? false;
  useEffect(() => setCheckmarks(initCheckmarks()), [loading]);

  const isEverythingSet = checkmarks.every((v) => v);

  function toggleValueAt(index: number) {
    setCheckmarks(checkmarks.map((v, i) => (i == index ? !v : v)));
  }

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Container>
      <div>
        <Checkbox
          checked={checkmarksCount > 0}
          onClick={() => setCheckmarks(checkmarks.map(() => !isEverythingSet))}
        >
          <DSCheckbox
            size="small"
            checked={checkmarksCount > 0}
            nestedSelection={checkmarksCount < props.options.length}
            onChange={() =>
              setCheckmarks(checkmarks.map(() => !isEverythingSet))
            }
            key={`${props.key}-checkbox-group-eveything`}
          />
          {t("selectAll")}
        </Checkbox>
      </div>
      <HorizontalLine />
      {props.options.map(({ label }, i) => (
        <Checkbox
          key={`${props.key}-checkbox-${i}`}
          checked={checkmarks[i]}
          onClick={() => toggleValueAt(i)}
        >
          <DSCheckbox
            size="small"
            onChange={() => toggleValueAt(i)}
            checked={checkmarks[i]}
            key={`${props.key}-checkbox-group-${i}`}
          />
          {label}
        </Checkbox>
      ))}
    </Container>
  );
};
